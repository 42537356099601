import {
  Group,
  GroupAttributes,
  GroupAttributesJson,
  GroupJson,
  PublicUser,
  PublicUserJson,
} from "../auth/types";
import api from "./api";
import { unmarshall as unmarshallUser } from "./userClient";

/**
 * Provides access to the permission group API
 */

async function getById(id: number): Promise<Group> {
  const response = await api.call(`/groups/${id}/`);
  return unmarshall((await response.json()) as GroupJson);
}

async function getMembersByGroupId(id: number): Promise<Array<PublicUser>> {
  const response = await api.call(`/groups/${id}/members/`);
  return parseUsers((await response.json()) as Array<PublicUserJson>);
}

async function removeMember(
  groupId: number,
  userId: number
): Promise<Array<PublicUser>> {
  const fetchInit = {
    method: "DELETE",
    body: JSON.stringify({ user_id: userId }),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await api.call(`/groups/${groupId}/members/`, fetchInit);
  return parseUsers((await response.json()) as Array<PublicUserJson>);
}

async function addMember(
  groupId: number,
  userId: number
): Promise<Array<PublicUser>> {
  const fetchInit = {
    method: "PATCH",
    body: JSON.stringify({ user_id: userId }),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await api.call(`/groups/${groupId}/members/`, fetchInit);
  return parseUsers((await response.json()) as Array<PublicUserJson>);
}

function unmarshall(json: GroupJson): Group {
  return {
    id: json.id,
    name: json.name,
    attributes: unmarshallAttributes(json.groupattributes),
  };
}

function unmarshallAttributes(json: GroupAttributesJson): GroupAttributes {
  return {
    friendlyName: json.friendly_name,
  };
}

function parseUsers(json: Array<PublicUserJson>): Array<PublicUser> {
  if (json) {
    return json.map((val) => {
      return unmarshallUser(val);
    });
  }
  return [];
}

const groupClient = {
  unmarshall: unmarshall,
  getById: getById,
  getMembersByGroupId: getMembersByGroupId,
  removeMember: removeMember,
  addMember: addMember,
};

export default groupClient;
