import { Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import { Redirect } from "react-router";
import { object, string } from "yup";
import sponsorUnitClient from "../api/sponsorUnitClient";
import { DEFAULT_ERROR_NOTIFICATION } from "../common/constants";
import { Notification, Redirect as RedirectType } from "../common/types";
import FormGenerator, {
  Field,
  FormSchema,
  Group,
} from "../components/forms/FormGenerator";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";
import { SponsorUnit } from "./types";

type SponsorUnitFormProps = {
  isDialog?: boolean;
  sponsorUnit?: SponsorUnit;
  saveText?: string;
  handleDialogClose?: () => void;
  onSubmit?: (sponsorUnit: SponsorUnit) => void;
};

/**
 * A form for a sponsor unit. If the props sponsor unit does not conatin an id
 * then create a new sponsor unit. Otherwise update the sponsor unit with that ID
 */
function SponsorUnitForm(props: SponsorUnitFormProps) {
  const [sponsorUnit] = useState(props.sponsorUnit || ({} as SponsorUnit));
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });
  const { setNotification } = useNotificationContext();

  /**
   * On submit either create a new sponsor unit or update the existing one.
   * If creating a sponsor unit redirect when that sponsor unit is created.
   */
  function handleSubmit(
    sponsorUnit: SponsorUnit,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    if (sponsorUnit.id) {
      sponsorUnitClient
        .update(sponsorUnit)
        .then((sponsorUnitResponse) => {
          setNotification({
            message: "Sponsor Unit Updated!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri: `/sponsor-units/${sponsorUnitResponse.slug ?? ""}`,
          });
          if (props.onSubmit !== undefined) {
            props.onSubmit(sponsorUnitResponse);
          }
        })
        .catch((e: Error) => {
          console.error("Unable to update sponsor unit: " + e.message);
          setNotification({
            message: DEFAULT_ERROR_NOTIFICATION,
            type: "error",
          });
          setSubmitting(false);
        });
    } else {
      sponsorUnitClient
        .create(sponsorUnit)
        .then((json) => {
          setNotification({
            message: "Sponsor Unit Created!",
            type: "success",
          } as Notification);
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri: `/sponsor-units/${json.slug ?? ""}`,
          });
          if (props.onSubmit !== undefined) {
            props.onSubmit(json);
          }
        })
        .catch((e: Error) => {
          console.error("Unable to create sponsor unit: " + e.message);
          setNotification({
            message: DEFAULT_ERROR_NOTIFICATION,
            type: "error",
          });
          setSubmitting(false);
        });
    }
  }

  /**
   * If the redirect object's redirect key is true redirect to the URI.
   */
  if (redirectObj.redirect && props.onSubmit === undefined) {
    return <Redirect push to={redirectObj.uri} />;
  }

  const validationSchema = object().shape({
    name: string().trim().required("Sponsor Unit Name is Required"),
  });

  const fields: Field[] = [
    {
      name: "name",
      prettyName: "Sponsoring Unit Name",
      type: "text",
      required: true,
      ariaLabel: "sponsor unit name",
    },
    {
      name: "resPerson",
      prettyName: "Dean or Director Name",
      type: "personSelect",
      required: false,
      ariaLabel: "risk executive sponsor",
      fieldProps: { hideCreateButton: props.isDialog },
    },
    {
      name: "oversightOffice",
      prettyName: "School or Department",
      type: "oversightOfficeSelect",
      required: false,
      ariaLabel: "oversight office",
    },
  ];

  const groups: Group[] = [
    {
      name: "sponsor-unit-form-group",
      prettyName: "Sponsor Unit Form",
      fields: fields,
    },
  ];

  const formSchema: FormSchema<SponsorUnit> = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: sponsorUnit,
    groups: groups,
    saveText: props.saveText,
    handleCancel: props.handleDialogClose,
  };

  return (
    <div>
      {props.isDialog ? (
        <Grid aria-label="new sponsoring unit form" container>
          <Grid item xs={12}>
            <FormGenerator schema={formSchema} />
          </Grid>
        </Grid>
      ) : (
        <Card aria-label="new sponsoring unit form" className="uw-card">
          <YarsCardHeader
            component="h2"
            title={
              sponsorUnit.name
                ? `Edit ${sponsorUnit.name || ""} Sponsoring Unit`
                : "Create New Sponsoring Unit"
            }
          />
          <CardContent>
            <FormGenerator schema={formSchema} />
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default SponsorUnitForm;
