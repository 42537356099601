import FilterListIcon from "@mui/icons-material/FilterList";
import { Paper, Typography } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridColTypeDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { announce } from "@react-aria/live-announcer";
import { useState } from "react";

type YarsTableProps<T> = {
  rows: T[];
  columns: GridColDef[];
  ariaLabel: string;
  ariaDescription?: string;
  isLoading?: boolean;
  showToolbar?: boolean;
};

export const yarsActions: GridColTypeDef = {
  type: "string",
  headerName: "Actions",
  sortable: false,
  width: 90,
  align: "right",
  headerAlign: "right",
  filterable: false,
  disableExport: true,
};

export const yesNoValueOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

function YarsNoRowsOverlay() {
  return (
    <Typography sx={{ paddingTop: "4rem" }} align="center">
      No data
    </Typography>
  );
}

function YarsToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton aria-haspopup="true" />
      <GridToolbarFilterButton
        aria-haspopup="true"
        componentsProps={{ button: { startIcon: <FilterListIcon /> } }}
      />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

// Assistive technology announcements

function filterApplied() {
  announce("Filtered table", "assertive");
}

function columnsVisibilityChanged() {
  announce("Columns visibility changed", "assertive");
}

function pageChanged() {
  announce("Table page changed", "assertive");
}

function tableSorted() {
  announce("Sorted table", "assertive");
}

function YarsTable<T>(props: YarsTableProps<T>) {
  const { isLoading, rows, columns, ariaLabel, ariaDescription, showToolbar } =
    props;

  const [pageSize, setPageSize] = useState<number>(5);

  return (
    <div>
      <Paper style={{ width: "100%" }}>
        {ariaDescription && (
          <p hidden id="data-grid-description">
            {ariaDescription}
          </p>
        )}
        <p className="screenreaderOnly">{`${ariaLabel}. This table consists of three main sections: Toolbar, Table Data, and Pagination. The Toolbar and Pagination sections are not accessible when navigating via screen reader reading order. Switch to tabbing to access these sections.`}</p>
        <DataGridPremium
          onColumnVisibilityChange={columnsVisibilityChanged}
          onPageChange={pageChanged}
          onSortModelChange={tableSorted}
          onFilterModelChange={filterApplied}
          components={{
            NoRowsOverlay: YarsNoRowsOverlay,
            Toolbar: showToolbar === false ? undefined : YarsToolbar,
          }}
          autoHeight
          aria-label={ariaLabel}
          aria-describedby="data-grid-description"
          loading={isLoading}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(pageSize: number) => setPageSize(pageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          disableSelectionOnClick
          disableColumnMenu
          localeText={{
            toolbarFilters: "Filters/Search",
          }}
        />
      </Paper>
    </div>
  );
}

export default YarsTable;
