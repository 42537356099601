import { DateTime } from "luxon";

/**
 * Return an ISO date string from a JS Date object
 * @param date {Date}
 * @returns {string}
 */
export const marshalJSDate = (date: Date): string => {
  return DateTime.fromJSDate(date).toISODate();
};

/**
 * Return a JS Date object from an ISO date string
 * @param dateString {string}
 * @returns {Date}
 */
export const unmarshalJSDate = (dateString: string): Date => {
  return DateTime.fromISO(dateString).toJSDate();
};
