import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Page from "../components/Page";
import { useSessionContext } from "../context/SessionContext";
import { WithTitle } from "../context/TitleContext";

function Logout() {
  const session = useSessionContext();
  const apiBaseUrl: string = process.env.REACT_APP_BACKEND_URL || "";
  const search = useLocation().search;
  const inactive = new URLSearchParams(search).get("inactive");
  let logoutRedirectUrl: string;

  if (apiBaseUrl) {
    logoutRedirectUrl = `${apiBaseUrl}/logout/`;
  }

  useEffect(() => {
    if (session.user) {
      window.open(logoutRedirectUrl, "_self");
    }
  }, [session]);

  return (
    <WithTitle title="You are signed out">
      <Page>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography paragraph>
              {inactive && <>You have been signed out due to inactivity. </>}
              <Link to="/login">Click here to sign back in</Link>
            </Typography>
          </Grid>
        </Grid>
      </Page>
    </WithTitle>
  );
}

export default Logout;
