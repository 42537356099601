import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

type DeleteDialogProps = {
  open: boolean;
  title: string;
  content: React.ReactElement;
  handleDelete: () => void;
  isDeleting: boolean;
  handleClose: () => void;
};

function DeleteDialog(props: DeleteDialogProps) {
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-content"
      open={props.open}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-content">
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={props.handleDelete}
          color="primary"
          autoFocus
          disabled={props.isDeleting}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
