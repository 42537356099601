import DoneIcon from "@mui/icons-material/Done";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import PriorityIcon from "@mui/icons-material/PriorityHigh";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import YarsCardHeader from "../../components/YarsCardHeader";
import NavLinkForward from "../../navigation/NavLinkForward";
import { Session } from "./../types";

function StaffCard(props: { session: Session }) {
  const location = useLocation();
  const session = props.session;

  return (
    <Card
      variant="outlined"
      aria-label="Staff details"
      className="uw-card-details"
    >
      <YarsCardHeader title="Staff" />
      <CardContent>
        <List dense={true} className="details-list">
          <ListItem>
            <ListItemAvatar
              className={session.staffCount ? "success" : "warning"}
            >
              <Avatar>
                {session.staffCount ? <GroupsIcon /> : <PriorityIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Staff Count"
              secondary={session.staffCount || "Unknown"}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar
              className={session.staffYoungestAge ? "success" : "warning"}
            >
              <Avatar>
                {session.staffYoungestAge ? <PersonIcon /> : <PriorityIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Youngest Staff Age"
              secondary={session.staffYoungestAge || "Unknown"}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar
              className={session.staffBackgroundChecks ? "success" : "warning"}
            >
              <Avatar>
                {session.staffBackgroundChecks ? (
                  <DoneIcon />
                ) : (
                  <PriorityIcon />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Background checks"
              secondary={
                session.staffBackgroundChecks ? "Completed" : "Unknown"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar
              className={session.staffRequiredTraining ? "success" : "warning"}
            >
              <Avatar>
                {session.staffRequiredTraining ? (
                  <DoneIcon />
                ) : (
                  <PriorityIcon />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Required training"
              secondary={
                session.staffRequiredTraining ? "Completed" : "Unknown"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar
              className={session.staffRequiredTraining ? "success" : "warning"}
            >
              <Avatar>
                {session.staffOrientation ? <DoneIcon /> : <PriorityIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Event with staff orientation"
              secondary={session.staffOrientation ? "Yes" : "Unknown"}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Box m={2}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                className="primary-action-btn"
                component={NavLinkForward}
                to={`${location.pathname}/staff`}
                size="medium"
                variant="contained"
              >
                Manage Staff
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardActions>
    </Card>
  );
}

export default StaffCard;
