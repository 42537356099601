import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Redirect } from "react-router";
import { Redirect as RedirectType } from "../common/types";

/**
 * Get minutes from an env function. Make sure that it is a number, and it is positive, otherwise return default minutes
 */
export function getEnvMinutes(env: unknown, defaultMinutes: number) {
  const NUM = Number(env);
  if (!isNaN(NUM) && NUM >= 0) {
    return NUM;
  } else {
    return defaultMinutes;
  }
}

/**
 * Handle an idle timeout modal and logout.
 */
export default function IdleTimeout() {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });

  /**
   ** Total timeout time. DIALOG_WARNING_THRESHOLD will be subracted from this to calculate when the dialog should be shown
   */
  const TIMEOUT_MINUTES = getEnvMinutes(
    process.env.REACT_APP_TIMEOUT_MINUTES,
    15
  );

  /**
   ** How many minutes the dialog should be open before the session is timed out.
   */
  const DIALOG_WARNING_THRESHOLD = getEnvMinutes(
    process.env.REACT_APP_DIALOG_OPEN_MINUTES,
    1
  );

  const LOGOUT_URI = "/logout?inactive=true";

  const OPEN_DIALOG_TIMEOUT =
    1000 * 60 * (TIMEOUT_MINUTES - DIALOG_WARNING_THRESHOLD);
  const DIALOG_TIMEOUT = 1000 * 60 * DIALOG_WARNING_THRESHOLD;

  const dialogTimer = useIdleTimer({
    timeout: DIALOG_TIMEOUT,
    onIdle: () => logout(),
    debounce: 500,
    startManually: true,
    events: [],
  });

  useIdleTimer({
    timeout: OPEN_DIALOG_TIMEOUT,
    onIdle: () => {
      dialogTimer.reset();
      setDialogOpen(true);
    },
    debounce: 500,
  });

  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  return (
    <Dialog
      open={dialogOpen}
      aria-labelledby="session-dialog-title"
      aria-describedby="session-dialog-content"
      fullWidth
      maxWidth="sm"
      className="uw-dialog"
    >
      <DialogTitle className="uw-dialog-title" id="session-dialog-title">
        Session Timeout
        <IconButton
          sx={{ float: "right" }}
          aria-label="Close dialog"
          data-testid="dialog-close"
          onClick={handleDialogClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="uw-dialog-content" id="session-dialog-content">
        You will be logged out due to inactivity.
      </DialogContent>
      <DialogActions>
        <Button
          className="secondary-action-btn"
          variant="contained"
          onClick={handleDialogClose}
        >
          I&apos;m Still Here
        </Button>
        <Button
          className="primary-action-btn"
          variant="contained"
          onClick={logout}
        >
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );

  /**
   * Handle Logout
   */
  function logout() {
    handleDialogClose();
    setRedirectObj({ redirect: true, uri: LOGOUT_URI });
  }

  /**
   * Handle dialog close.
   */
  function handleDialogClose() {
    dialogTimer.pause();
    setDialogOpen(false);
  }
}
