/**
 * Common functionality to support API calls
 */

import Cookies from "js-cookie";
import { ApiError, FieldErrors } from "./errors";

/** The base URL of the backend API */
export const apiBaseUrl = process.env.REACT_APP_BACKEND_URL || "";

if (apiBaseUrl === "") {
  throw new ApiError("REACT_APP_BACKEND_URL is not defined");
}

/**
 * Wraps a fetch() call to the backend API.
 *
 * @param resource  the URL portion after apiBaseUrl. Must be prepended with slash.  eg: "/people"
 * @param init  identical to fetch()'s init options (HTTP verb, request body, etc.)
 * @param isMultipart boolean
 *
 * Throws an ApiError if an unexpected error or non-OK response code is encountered.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
 */
async function call(
  resource: string,
  init?: RequestInit,
  isMultipart: boolean = false
): Promise<Response> {
  if (!resource.startsWith("/")) {
    throw new ApiError("resource must begin with a forward slash (/)");
  }

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("X-CSRFToken", Cookies.get("csrftoken") || "");

  if (!isMultipart) {
    requestHeaders.set("Content-Type", "application/json");
  }

  const sendInit = {
    ...init,
    credentials: "include",
    headers: requestHeaders,
  } as RequestInit;

  const response = await fetch(apiBaseUrl + resource, sendInit);

  if (!response.ok) {
    return response.text().then((text) => {
      throw new ApiError(
        `${response.status} ${response.statusText}`,
        response,
        text ? (JSON.parse(text) as FieldErrors) : undefined
      );
    });
  }

  return response;
}

export const api = {
  call: call,
};

export default api;
