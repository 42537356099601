import PersonIcon from "@mui/icons-material/Person";
import { Avatar } from "@mui/material";
import { ReactElement } from "react";
import { User } from "../auth/types";

interface UserAvatarProps {
  user?: User;
}

/**
 * Renders a Material UI Avatar for a User.
 *
 * Properties:
 *  user: a User object
 *  backgroundColor: desired background color (a properly contrasted text color will be generated)
 *  borderColor: color of avatar border (no border is drawn if omitted)
 */
const UserAvatar = ({ user }: UserAvatarProps) => {
  let avatarElement: ReactElement | string = (
    <PersonIcon data-testid="PersonIcon" />
  );

  // Calculate initials based on name, falling back on NetID
  if (user) {
    let initials = "";
    if (user.firstName) {
      initials += user.firstName[0];
    }
    if (user.lastName) {
      initials += user.lastName[0];
    }
    if (!initials && user.netid) {
      initials += user.netid[0];
    }
    if (initials) {
      avatarElement = initials.toUpperCase();
    }
  }

  return <Avatar aria-hidden="true">{avatarElement}</Avatar>;
};

export default UserAvatar;
