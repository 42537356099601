import { Route, Switch, useRouteMatch } from "react-router";
import PageNotFound from "../../components/PageNotFound";
import StaffEdit from "./StaffEdit";

function Staff() {
  const match = useRouteMatch();

  if (match === null) {
    throw new Error("Staff route not matched");
  }

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <StaffEdit />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default Staff;
