import {
  OversightOffice,
  OversightOfficeJson,
} from "../oversight-offices/types";
import { Person, PersonJson } from "../people/types";

/**
 * Defines interfaces/types for Sponsor Units.
 */

/**
 * A sponsor unit is the unit that sponsors many programs.
 */
export interface SponsorUnit {
  id: number;
  name: string;
  slug: string;
  resPerson?: Person | number;
  oversightOffice?: OversightOffice | number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface SponsorUnitJson {
  id: number;
  name: string;
  slug: string;
  /** API returns Person object but expects a person ID when creating or updating. */
  res_person?: number | PersonJson;
  oversight_office?: number | OversightOfficeJson;
  created_at?: string;
  updated_at?: string;
}

export function isSponsorUnit(
  sponsorUnit: SponsorUnit | number
): sponsorUnit is SponsorUnit {
  return (sponsorUnit as SponsorUnit).id !== undefined;
}

export function isSponsorUnitJson(
  sponsorUnit: SponsorUnitJson | number
): sponsorUnit is SponsorUnitJson {
  return (sponsorUnit as SponsorUnitJson).id !== undefined;
}
