import { Route, Switch, useRouteMatch } from "react-router";
import PageNotFound from "../components/PageNotFound";
import PeopleList from "./PeopleList";
import PersonDetail from "./PersonDetail";
import PersonEdit from "./PersonEdit";

/**
 * Defines routing for the /people endpoint
 */
export const People = () => {
  const match = useRouteMatch();

  if (match === null) {
    throw new Error("People route not matched");
  }

  return (
    <Switch>
      <Route exact path={match.path}>
        <PeopleList />
      </Route>
      <Route path={[`${match.path}/create`, `${match.path}/:personSlug/edit`]}>
        <PersonEdit />
      </Route>
      <Route path={`${match.path}/:personSlug`}>
        <PersonDetail />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default People;
