import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiError } from "../api/errors";
import programClient from "../api/programClient";
import ErrorMessage from "../components/ErrorMessage";
import ListPage from "../components/ListPage";
import { useSessionContext } from "../context/SessionContext";
import ProgramsTable from "./ProgramsTable";
import { Program, Status } from "./types";

/**
 * Displays a list of Programs
 */
const ProgramsList = function () {
  const session = useSessionContext();
  const [programs, setPrograms] = useState([] as Array<Program>);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({} as ApiError);

  const status =
    new URLSearchParams(useLocation().search).get("status") ?? null;
  let createLink = undefined;

  useEffect(() => {
    setIsLoading(true);
    if (status) {
      programClient
        .getByStatus(status as Status)
        .then((programs) => setPrograms(programs))
        .catch((e: ApiError) => setApiError(e))
        .finally(() => setIsLoading(false));
    } else {
      programClient
        .getActive()
        .then((programs) => setPrograms(programs))
        .catch((e: ApiError) => setApiError(e))
        .finally(() => setIsLoading(false));
    }
  }, [setIsLoading, setApiError]);

  if (
    session.user &&
    session.user.permissions.includes("programs.add_program")
  ) {
    createLink = "/programs/create";
  }

  return (
    <ListPage
      table={
        apiError.message ? (
          <ErrorMessage message="Unable to retrieve active programs. Please try again." />
        ) : (
          <ProgramsTable programs={programs} isLoading={isLoading} />
        )
      }
      createLink={createLink}
      createText="Create Program"
    />
  );
};

export default ProgramsList;
