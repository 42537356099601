import {
  ContactPage,
  ContactSupport,
  Dashboard,
  Event,
  Person,
  Work,
} from "@mui/icons-material";
import { NavigationItem } from "../common/types";

/**
 * A default list of navigation items for the application
 */
export const defaultNavigationItems: Array<NavigationItem> = [
  {
    path: "/",
    title: "Dashboard",
    icon: <Dashboard />,
    exact: true,
  },
  {
    path: "/programs",
    title: "Programs",
    icon: <Event />,
    exact: false,
  },
  {
    path: "/sessions",
    title: "Sessions",
    icon: <ContactPage />,
    exact: false,
  },
  {
    path: "/sponsor-units",
    title: "Sponsor Units",
    permission: "sponsor_units.add_sponsorunit",
    icon: <Work />,
    exact: false,
  },
  {
    path: "/people",
    title: "People",
    permission: "people.add_person",
    icon: <Person />,
    exact: false,
  },
  {
    path: "/support",
    title: "Support",
    icon: <ContactSupport />,
    exact: false,
  },
];

export default defaultNavigationItems;
