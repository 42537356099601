/**
 * Returns the value of an environment variable configured with dot-env.
 *
 * @param name environment variable name
 * @return  value of environment variable
 * @throws Error if variable is not set
 */
export function getEnv(name: string): string {
  const value = process.env[name.toUpperCase()];
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined`);
  }
  return value;
}
