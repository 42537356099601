import { Route, Switch, useRouteMatch } from "react-router";
import PageNotFound from "../components/PageNotFound";
import { WithTitle } from "../context/TitleContext";
import { ProgramSessions } from "../sessions/Sessions";
import ProgramDetail from "./ProgramDetail";
import ProgramEdit from "./ProgramEdit";
import ProgramsList from "./ProgramsList";

/**
 * Defines routing for the /programs endpoint
 */
export const Programs = () => {
  const match = useRouteMatch();

  if (match === null) {
    throw new Error("Programs route not matched");
  }

  return (
    <Switch>
      <Route path={`${match.path}/:programSlug/sessions`}>
        <WithTitle title="Sessions">
          <ProgramSessions />
        </WithTitle>
      </Route>
      {/* A separate <Switch> is used to avoid bugs with setting nested titles */}
      <Route path={match.path}>
        <WithTitle title="Programs">
          <Switch>
            <Route exact path={`${match.path}/create`}>
              <ProgramEdit />
            </Route>
            <Route exact path={`${match.path}/:programSlug/edit`}>
              <ProgramEdit />
            </Route>
            <Route exact path={`${match.path}/:programSlug`}>
              <ProgramDetail />
            </Route>
            <Route path={match.path}>
              <ProgramsList />
            </Route>
          </Switch>
        </WithTitle>
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default Programs;
