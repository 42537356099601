import { Organization, OrganizationJson, User, UserJson } from "../auth/types";
import api from "./api";
import { ApiError } from "./errors";

async function getMe(): Promise<User> {
  const response = await api.call("/me/");
  return unmarshall(await response.json());
}

async function logout(): Promise<Response> {
  const response = await api.call("/logout/");
  return response;
}

async function getOrganizations(): Promise<Array<Organization>> {
  const response = await api.call("/organizations/");
  const json = (await response.json()) as Array<OrganizationJson>;
  return json.map((orgJson) => unmarshallOrganization(orgJson));
}

async function switchOrganization(id: number): Promise<Organization> {
  const response = await api.call(`/organizations/${id}/switch/`);
  return unmarshallOrganization((await response.json()) as OrganizationJson);
}

async function updateUser(user: Partial<User>): Promise<User> {
  if (!user.id) {
    throw new ApiError(`User has missing ID: ${user.toString()}`);
  }

  const response = await api.call(`/users/${user.id}/`, {
    method: "PUT",
    body: JSON.stringify(marshall(user)),
    headers: { "Content-Type": "application/json" },
  });
  return unmarshall(await response.json());
}

export function unmarshall(json: UserJson): User {
  return {
    id: json["id"],
    uid: json["username"],
    isActive: json["is_active"],
    isStaff: json["is_staff"],
    isSuperuser: json["is_superuser"],
    isOrganizationLiaison: json["is_organization_liaison"],
    permissions: json["permissions"],
    organization: unmarshallOrganization(json["organization"]),
    groups: json["groups"],
    firstName: json["first_name"],
    lastName: json["last_name"],
    email: json["email"],
  };
}

export function marshall(user: Partial<User>): Partial<UserJson> {
  return {
    id: user.id,
    username: user.uid,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
  };
}

export function unmarshallOrganization(json: OrganizationJson): Organization {
  return {
    id: json["id"],
    name: json["name"],
    eppnDomain: json["eppn_domain"],
  };
}

export const authClient = {
  getMe: getMe,
  updateUser: updateUser,
  getOrganizations: getOrganizations,
  switchOrganization: switchOrganization,
  unmarshall: unmarshall,
  logout: logout,
};

export default authClient;
