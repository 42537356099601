import { Grid } from "@mui/material";
import Page from "../components/Page";
import { useSessionContext } from "../context/SessionContext";
import ProgramCard from "../programs/ProgramCard";
import ProgramsSubmittedList from "../programs/ProgramsSubmittedList";

/** A dashboard of useful information and links */
export const Dashboard = () => {
  const { user } = useSessionContext();
  return (
    <Page>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ProgramCard />
        </Grid>
        {(user?.isOrganizationLiaison || user?.isSuperuser) && (
          <Grid item xs={12} md={6}>
            <ProgramsSubmittedList />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default Dashboard;
