import ListPage from "../components/ListPage";
import { useSessionContext } from "../context/SessionContext";
import SponsorUnitsTable from "./SponsorUnitsTable";

/**
 * List all of the sponsor units.
 */
const SponsorUnitsList = function () {
  const session = useSessionContext();
  let createLink = undefined;

  if (
    session.user &&
    session.user.permissions.includes("sponsor_units.add_sponsorunit")
  ) {
    createLink = "/sponsor-units/create/";
  }
  return (
    <ListPage
      table={<SponsorUnitsTable />}
      createLink={createLink}
      createText="Create Sponsor Unit"
    />
  );
};

export default SponsorUnitsList;
