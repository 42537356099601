import { Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import { Redirect } from "react-router";
import { object, string } from "yup";
import { ApiError } from "../api/errors";
import personClient from "../api/personClient";
import { DEFAULT_ERROR_NOTIFICATION } from "../common/constants";
import { Redirect as RedirectType } from "../common/types";
import FormGenerator, {
  Field,
  FormSchema,
  Group,
} from "../components/forms/FormGenerator";
import Timestamp from "../components/Timestamp";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";
import { Person } from "./types";

type PersonFormProps = {
  isDialog?: boolean;
  person?: Person;
  autoFocus?: boolean;
  showHeader?: boolean;
  onSubmit?: (person: Person) => void;
  saveText?: string;
  handleDialogClose?: () => void;
};

/**
 * A form for a person. If the props person does not conatin an id
 * then create a new person. Otherwise update the person with that ID
 */
function PersonForm(props: PersonFormProps) {
  const [person] = useState<Person>(props.person || ({} as Person));
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });
  const { setNotification } = useNotificationContext();

  /**
   * On submit either create a new person or update the existing one.
   * If creating a person redirect when that person is created.
   */
  function handleSubmit(
    person: Person,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    if (person.id) {
      personClient
        .update(person)
        .then((personResponse) => {
          if (!personResponse.slug) {
            throw new Error(
              `Person does not have slug after update: ${person.email}`
            );
          }
          setNotification({
            message: "Person Updated!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri: "/people/" + personResponse.slug,
          });
          if (props.onSubmit !== undefined) {
            props.onSubmit(personResponse);
          }
        })
        .catch((e: Error) => {
          console.error(`Unable to save person: ${e.message}`);
          setNotification({
            message: DEFAULT_ERROR_NOTIFICATION,
            type: "error",
          });
          setSubmitting(false);
        });
    } else {
      personClient
        .create(person)
        .then((json) => {
          if (!json.slug) {
            throw new Error(
              `Person does not have slug after creation: ${person.email}`
            );
          }
          setNotification({
            message: "Person Created!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri: "/people/" + json.slug,
          });
          if (props.onSubmit !== undefined) {
            props.onSubmit(json);
          }
        })
        .catch((e: Error) => {
          if (e instanceof ApiError && e.fieldErrors) {
            const errors = e.fieldErrors;
            // eslint-disable-next-line
            for (const [_, errorMessage] of Object.entries(errors)) {
              setNotification({
                message: errorMessage,
                type: "error",
              });
            }
          } else {
            setNotification({
              message: DEFAULT_ERROR_NOTIFICATION,
              type: "error",
            });
          }
          setSubmitting(false);
        });
    }
  }

  /**
   * If the redirect object's redirect key is true redirect to the URI.
   */
  if (redirectObj.redirect && props.onSubmit === undefined) {
    return <Redirect push to={redirectObj.uri} />;
  }

  const validationSchema = object().shape({
    givenName: string().trim().required("First Name is Required"),
    surname: string().trim().required("Last Name is Required"),
    email: string()
      .email("Please Enter a Valid Email")
      .required("Email is Required"),
    phoneNumber: string().trim(),
  });
  const fields: Field[] = [
    {
      name: "givenName",
      prettyName: "First Name",
      type: "text",
      required: true,
      ariaLabel: "first name",
    },
    {
      name: "surname",
      prettyName: "Last Name",
      type: "text",
      required: true,
      ariaLabel: "last name",
    },
    {
      name: "email",
      prettyName: "Email",
      type: "text",
      required: true,
      ariaLabel: "email",
    },
    {
      name: "phoneNumber",
      prettyName: "Phone Number",
      type: "text",
      required: false,
      ariaLabel: "phone number",
    },
  ];

  const groups: Group[] = [
    {
      name: "person-form-group",
      prettyName: "Person Form",
      fields: fields,
    },
  ];

  const formSchema: FormSchema<Person> = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: person,
    groups: groups,
    saveText: props.saveText,
    handleCancel: props.handleDialogClose,
  };

  return (
    <div>
      {props.isDialog ? (
        <Grid aria-label="new person form" container>
          <Grid item xs={12}>
            <FormGenerator schema={formSchema} aria-live="polite" />
            {person.createdAt && person.updatedAt && (
              <Grid item xs={12}>
                <Timestamp
                  createdAt={person.createdAt}
                  updatedAt={person.updatedAt}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Card aria-label="new person form" className="uw-card">
          <YarsCardHeader
            component="h2"
            title={
              person.fullName
                ? `Edit ${person.fullName || ""}`
                : "Create New Person"
            }
          />
          <CardContent>
            <FormGenerator schema={formSchema} aria-live="polite" />
            {person.createdAt && person.updatedAt && (
              <Grid item xs={12}>
                <Timestamp
                  createdAt={person.createdAt}
                  updatedAt={person.updatedAt}
                />
              </Grid>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default PersonForm;
