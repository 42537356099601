import { Route, Switch, useRouteMatch } from "react-router";
import PageNotFound from "../../components/PageNotFound";
import ParticipantEdit from "./ParticipantEdit";

export const Participants = () => {
  const match = useRouteMatch();

  if (match === null) {
    throw new Error("Participants are not matched");
  }

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <ParticipantEdit />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default Participants;
