import { Person, PersonJson } from "../people/types";
/**
 * Defines interfaces for Oversight Offices.
 */

export interface OversightOffice {
  id: number;
  name: string;
  oversightPerson?: Person | number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface OversightOfficeJson {
  id: number;
  name: string;
  oversight_person?: PersonJson | number;
  created_at?: string;
  updated_at?: string;
}

export function isOversightOffice(
  oversightOffice: OversightOffice | number
): oversightOffice is OversightOffice {
  return (oversightOffice as OversightOffice).id !== undefined;
}

export function isOversightOfficeJson(
  oversightOffice: OversightOfficeJson | number
): oversightOffice is OversightOfficeJson {
  return (oversightOffice as OversightOfficeJson).id !== undefined;
}
