import { Menu } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Container,
  IconButton,
  List,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { SkipNavContent } from "@reach/skip-nav";
import clsx from "clsx";
import { useEffect, useState } from "react";
import PageHeadings from "../components/PageHeadings";
import { useSessionContext } from "../context/SessionContext";
import { useTitleContext } from "../context/TitleContext";
import AccountMenu from "./AccountMenu";
import defaultNavigationItems from "./defaultNavigationItems";
import ListItemLink from "./ListItemLink";
import NavLinkForward from "./NavLinkForward";
import Routes from "./Routes";

const drawerWidth = "240px";

/**
 * Primary component of the application. Renders an app bar, navigation drawer, and the content for the active route.
 */
export const Main = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const [isDrawerOpen, setIsDrawerOpen] = useState(!isMobile);
  const { appName, appNameShort } = useTitleContext();
  const session = useSessionContext();

  useEffect(() => {
    setIsDrawerOpen(!isMobile);
  }, [isMobile, setIsDrawerOpen]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const closeDrawerIfMobile = () => {
    if (isMobile) {
      setIsDrawerOpen(false);
    }
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        className={clsx("app-header")}
        sx={{
          zIndex: isMobile && isDrawerOpen ? 0 : theme.zIndex.drawer + 1,
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open navigation menu"
            onClick={openDrawer}
            className="uw-menu-btn"
            sx={{
              display: isDrawerOpen ? "none" : "block",
              mr: theme.spacing(2),
            }}
            size="large"
          >
            <Menu />
          </IconButton>
          <Typography
            variant="h6"
            component="span"
            sx={{ flex: 1, lineHeight: 1.2 }}
          >
            <NavLinkForward to="/" exact={true} className="appNameLink">
              {isMobile ? appNameShort : appName}
            </NavLinkForward>
          </Typography>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isDrawerOpen}
        onOpen={openDrawer}
        onClose={closeDrawer}
        PaperProps={{ sx: { whiteSpace: "nowrap", width: drawerWidth } }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            py: 0,
            px: "8px",
            ...theme.mixins.toolbar,
          }}
        >
          {isMobile && (
            <IconButton
              onClick={closeDrawer}
              aria-label="close navigation menu"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        <Box component="nav" onClick={closeDrawerIfMobile}>
          <List className="mainNavigationList">
            {defaultNavigationItems.map((item) => {
              const user_permissions = session.user?.permissions || [];
              if (
                !item.permission ||
                user_permissions.includes(item.permission)
              ) {
                return (
                  <ListItemLink
                    primary={item.title}
                    to={item.path}
                    icon={item.icon}
                    key={item.path}
                    exact={item.exact}
                  />
                );
              }
            })}
          </List>
        </Box>
      </SwipeableDrawer>
      <Box
        component="main"
        sx={{
          ml: isDrawerOpen && !isMobile ? drawerWidth : 0,
          py: "24px",
          px: 0,
          flexGrow: 1,
          transition: theme.transitions.create("margin", {
            easing:
              isDrawerOpen && !isMobile
                ? theme.transitions.easing.easeOut
                : theme.transitions.easing.sharp,
            duration:
              isDrawerOpen && !isMobile
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <SkipNavContent>
          <Container maxWidth="lg" sx={{ py: theme.spacing(4) }}>
            <PageHeadings />
            <Routes />
          </Container>
        </SkipNavContent>
      </Box>
    </Box>
  );
};

export default Main;
