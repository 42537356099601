import UpdateIcon from "@mui/icons-material/Update";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { DateTime } from "luxon";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiError } from "../api/errors";
import programClient from "../api/programClient";
import ManageGroupCard from "../auth/ManageGroupCard";
import ErrorMessage from "../components/ErrorMessage";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";
import { WithTitle } from "../context/TitleContext";
import NavLinkForward from "../navigation/NavLinkForward";
import ProgramStatus from "../programs/ProgramStatus";
import SessionsList from "../sessions/SessionsList";
import SponsorUnitInfoCard from "../sponsor-units/SponsorUnitInfoCard";
import { isSponsorUnit } from "../sponsor-units/types";
import ProgramDenialDialog from "./ProgramDenialDialog";
import { Program, Status } from "./types";

function ProgramDetail() {
  const [program, setProgram] = useState<Program>({} as Program);
  const [isUpdating, setIsUpdating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [apiError, setApiError] = useState<ApiError | null>(null);
  const { setNotification } = useNotificationContext();
  const { programSlug } = useParams<{ programSlug: string }>();
  const sponsorUnit = program.sponsorUnit;

  const updateStatus = (program: Program, status: Status) => {
    setIsUpdating(true);
    programClient
      .update({
        id: program.id,
        name: program.name,
        status: status,
      })
      .then((program) => {
        setProgram((old) => {
          const updated = {} as Program;
          Object.assign(updated, old);
          updated.status = program.status;
          updated.updatedAt = program.updatedAt;
          return updated;
        });
        setNotification({
          message: `Program ${program.status}`,
        });
        setIsUpdating(false);
      })
      .catch((e) => {
        setApiError(e);
        setIsUpdating(false);
      });
  };

  const submitProgram = (program: Program) => {
    updateStatus(program, "submitted");
  };

  const reviewProgram = (program: Program) => {
    updateStatus(program, "reviewed");
  };

  useEffect(() => {
    if (programSlug) {
      programClient
        .getBySlug(programSlug)
        .then((programResponse) => setProgram(programResponse))
        .catch((e: ApiError) => setApiError(e));
    }
  }, [programSlug]);

  return (
    <>
      {apiError ? (
        <ErrorMessage message="No Program Found" />
      ) : (
        <WithTitle subtitle={program.name}>
          <ProgramDenialDialog
            program={program}
            open={dialogOpen}
            handleClose={() => setDialogOpen(false)}
            afterSubmit={() => {
              setIsUpdating(false);
              setDialogOpen(false);
            }}
            setProgram={setProgram}
          />

          <Grid container spacing={3} className="uw-detailsPage">
            <Grid item xs={12}>
              <ProgramStatus
                program={program}
                onSubmit={() => submitProgram(program)}
                onReview={() => reviewProgram(program)}
                onDeny={() => setDialogOpen(true)}
                isUpdating={isUpdating}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProgramStatusCard program={program} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SponsorUnitInfoCard
                program={program}
                sponsorUnit={
                  sponsorUnit && isSponsorUnit(sponsorUnit)
                    ? sponsorUnit
                    : undefined
                }
              />
            </Grid>
            {program.sessions && (
              <Grid item xs={12} md={12}>
                <SessionsList sessions={program.sessions} program={program} />
              </Grid>
            )}
            {program.permissions?.includes("assign_program_permission") && (
              <>
                {program.groups?.map((group) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={group.id}
                      className="uw-table-container"
                    >
                      <ManageGroupCard group={group} />
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </WithTitle>
      )}
    </>
  );
}

function ProgramStatusCard(props: { program: Program }) {
  const program = props.program;

  function cardActions(): ReactNode {
    if (program.permissions?.includes("change_program")) {
      return (
        <Button
          className="secondary-action-btn"
          component={NavLinkForward}
          to={`/programs/${program.slug}/edit`}
          size="medium"
        >
          Edit Program
        </Button>
      );
    }
    return <></>;
  }

  return (
    <Card
      variant="outlined"
      aria-label="Program status"
      className={`uw-card-details`}
    >
      <YarsCardHeader title="Program Details" />
      <CardContent>
        <List dense={true} className="details-list">
          <ListItem aria-labelledby="programUpdatedAt">
            <ListItemAvatar>
              <Avatar>
                <UpdateIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="programUpdatedAt"
              primary="Last Updated"
              secondary={
                program.updatedAt
                  ? DateTime.fromJSDate(program.updatedAt).toLocaleString(
                      DateTime.DATETIME_MED
                    )
                  : "Never updated"
              }
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>{cardActions()}</CardActions>
    </Card>
  );
}

export default ProgramDetail;
