/**
 * Provides access to the Oversight Office YARS API
 */
import {
  OversightOffice,
  OversightOfficeJson,
} from "../oversight-offices/types";
import { isPerson, isPersonJson } from "../people/types";
import { api } from "./api";
import { ApiError } from "./errors";
import personClient from "./personClient";

/**
 * Fetches all active Oversight Offices
 */
async function getAll(): Promise<Array<OversightOffice>> {
  const response = await api.call("/oversight-offices?ordering=name");
  const json = (await response.json()) as Array<OversightOfficeJson>;
  return json.map((oversightOfficeJson) => unmarshall(oversightOfficeJson));
}

/**
 * Fetches oversight office by id
 */
async function getById(id: number): Promise<OversightOffice> {
  const response = await api.call(`/oversight-offices/${id}/`);
  return unmarshall((await response.json()) as OversightOfficeJson);
}

/**
 * Updates an oversight office
 */
async function update(
  oversightOffice: OversightOffice
): Promise<OversightOffice> {
  console.warn(
    "Oversight offices must be managed statically in the backend. This call will not work."
  );
  if (!oversightOffice.id) {
    throw new ApiError("oversightOffice.id is missing");
  }

  // Enforce oversight person to be integer
  if (
    oversightOffice.oversightPerson &&
    isPerson(oversightOffice.oversightPerson)
  ) {
    oversightOffice.oversightPerson = oversightOffice.oversightPerson.id;
  }

  const fetchInit = {
    method: "PUT",
    body: JSON.stringify(marshall(oversightOffice)),
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await api.call(
    `/oversight-offices/${oversightOffice.id}/`,
    fetchInit
  );
  const json = (await response.json()) as OversightOfficeJson;
  return unmarshall(json);
}

/**
 * Create an oversight office
 */
async function create(
  oversightOffice: OversightOffice
): Promise<OversightOffice> {
  console.warn(
    "Oversight offices must be managed statically in the backend. This call will not work."
  );
  if (
    oversightOffice.oversightPerson &&
    isPerson(oversightOffice.oversightPerson)
  ) {
    oversightOffice.oversightPerson = oversightOffice.oversightPerson.id;
  }
  const fetchBody = {
    method: "POST",
    body: JSON.stringify(marshall(oversightOffice)),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await api.call("/oversight-offices/", fetchBody);
  const json = (await response.json()) as OversightOfficeJson;
  return unmarshall(json);
}

/**
 * Deletes an oversight office
 */
async function deleteById(id: number): Promise<void> {
  console.warn(
    "Oversight offices must be managed statically in the backend. This call will not work."
  );
  const fetchBody = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };
  await api.call(`/oversight-offices/${id}/`, fetchBody);
}

/** Marshalls an Oversight Office into JSON */
function marshall(
  oversightOffice: Partial<OversightOffice>
): Partial<OversightOfficeJson> {
  const oversightPerson = oversightOffice.oversightPerson;
  return {
    id: oversightOffice.id,
    name: oversightOffice.name,
    oversight_person:
      oversightPerson && isPerson(oversightPerson)
        ? oversightPerson.id
        : oversightPerson,
    created_at: oversightOffice.createdAt
      ? oversightOffice.createdAt.toISOString()
      : undefined,
    updated_at: oversightOffice.updatedAt
      ? oversightOffice.updatedAt.toISOString()
      : undefined,
  };
}

/** Unmarshalls a JSON respresentation of an overssight office into a OversightOffice object */
function unmarshall(json: OversightOfficeJson): OversightOffice {
  return {
    id: json.id,
    name: json.name,
    oversightPerson:
      json.oversight_person && isPersonJson(json.oversight_person)
        ? personClient.unmarshall(json.oversight_person)
        : json.oversight_person,
    createdAt: json.created_at ? new Date(json.created_at) : undefined,
    updatedAt: json.updated_at ? new Date(json.updated_at) : undefined,
  };
}

const oversightOfficeClient = {
  getAll: getAll,
  getById: getById,
  update: update,
  create: create,
  deleteById: deleteById,
  marshall: marshall,
  unmarshall: unmarshall,
};

export default oversightOfficeClient;
