import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import React from "react";
import oversightOfficeClient from "../../api/oversightOfficeClient";
import { OversightOffice } from "../../oversight-offices/types";

interface OversightOfficePickerProps {
  onOversightOfficeChange: (oversightOffice: OversightOffice | null) => void;
  label?: string;
  defaultValue?: OversightOffice;
}

function OversightOfficePicker(props: OversightOfficePickerProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<OversightOffice[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedOversightOffice, setSelectedOversightOffice] =
    React.useState<OversightOffice | null>(props.defaultValue || null);

  /**
   * If not currently loading get, and set the oversight offices.
   */
  React.useEffect(() => {
    if (loading) {
      oversightOfficeClient
        .getAll()
        .then((oversightOffices) => setOptions(oversightOffices))
        .catch((e: Error) =>
          console.error(`Unable to fetch oversight offices: ${e.toString()}`)
        )
        .finally(() => setLoading(false));
    }
  }, [loading]);

  /**
   * When the select is closed set the options to null.
   */
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  /**
   * On input change update state
   */
  function handleChange(e: any, value: OversightOffice | null) {
    setSelectedOversightOffice(value);
    props.onOversightOfficeChange(value);
  }

  return (
    <Autocomplete
      open={open}
      value={selectedOversightOffice}
      id="oversight-office-picker"
      onOpen={() => {
        setOpen(true);
        setLoading(true);
      }}
      onClose={() => {
        setOpen(false);
        setLoading(false);
      }}
      onChange={(event, value) => handleChange(event, value)}
      isOptionEqualToValue={(option, value) => {
        if (value.id === option.id) {
          return true;
        }
        return false;
      }}
      getOptionLabel={(option) => `${option.name}`}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label || "Oversight Office"}
          fullWidth
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default OversightOfficePicker;
