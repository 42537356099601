import { Button, Card, CardContent } from "@mui/material";
import { useState } from "react";
import YarsCardHeader from "../components/YarsCardHeader";
import ManageGroup from "./ManageGroup";
import { Group } from "./types";

interface ManageGroupCardProps {
  group: Group;
}

export default function ManageGroupCard(props: ManageGroupCardProps) {
  const group = props.group;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  function cardAction() {
    return (
      <Button
        aria-haspopup="true"
        aria-label="Open Select User dialog"
        variant="outlined"
        onClick={() => setDialogOpen(true)}
        className="primary-action-btn"
      >
        Select User
      </Button>
    );
  }

  function handleClose() {
    setDialogOpen(false);
  }

  const cardTitle = "Program Admins";
  return (
    <Card
      variant="outlined"
      aria-label="manage program groups"
      className="uw-card"
    >
      <YarsCardHeader title={cardTitle} action={cardAction()} />
      <CardContent className="uw-table-container">
        <ManageGroup
          group={group}
          dialogOpen={dialogOpen}
          handleClose={handleClose}
        />
      </CardContent>
    </Card>
  );
}
