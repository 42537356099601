import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
} from "@mui/material";

export const YarsDialog = (props: DialogProps) => {
  const { children, ...rest } = props;
  return (
    <Dialog fullWidth maxWidth="sm" className="uw-dialog" {...rest}>
      {children}
    </Dialog>
  );
};

export const YarsDialogTitle = (props: DialogTitleProps) => {
  const { children, ...rest } = props;
  return (
    <DialogTitle className="uw-dialog-title" {...rest}>
      {children}
    </DialogTitle>
  );
};

export const CloseDialogButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <IconButton
      aria-label="Close dialog"
      data-testid="dialog-close"
      onClick={onClose}
      size="large"
    >
      <CloseIcon />
    </IconButton>
  );
};

export const YarsDialogContent = (props: DialogContentProps) => {
  const { children, ...rest } = props;
  return (
    <DialogContent className="uw-dialog-content" {...rest}>
      {children}
    </DialogContent>
  );
};
