import { Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { object, string } from "yup";
import programClient from "../api/programClient";
import FormGenerator, { Field, Group } from "../components/forms/FormGenerator";
import {
  CloseDialogButton,
  YarsDialog,
  YarsDialogContent,
  YarsDialogTitle,
} from "../components/YarsDialog";
import { Program } from "./types";

const ProgramDenialDialog = ({
  program,
  open,
  handleClose,
  afterSubmit,
  setProgram,
}: {
  program: Program;
  open: boolean;
  handleClose: () => void;
  afterSubmit: () => void;
  setProgram: Dispatch<SetStateAction<Program>>;
}) => {
  const handleSubmit = (program: Partial<Program>) => {
    programClient
      .update({ ...program, status: "denied" })
      .then((program) => {
        setProgram((old) => {
          const updated = {} as Program;
          Object.assign(updated, old);
          updated.status = program.status;
          updated.updatedAt = program.updatedAt;
          updated.liaisonComments = program.liaisonComments;
          return updated;
        });
        afterSubmit();
      })
      .catch((error) => console.error(error));
    afterSubmit();
  };

  const validationSchema = object().shape({
    liaisonComments: string().trim().required("Denial Reason is Required"),
  });

  const fields: Field[] = [
    {
      name: "liaisonComments",
      prettyName: "Reason",
      type: "text",
      required: true,
      ariaLabel: "denial reason",
      fieldProps: {
        multiline: true,
        minRows: 5,
        maxRows: 5,
      },
    },
  ];

  const groups: Group[] = [
    {
      name: "program-denial-form-group",
      prettyName: "Program Denial Reason Form",
      fields: fields,
    },
  ];

  const schema = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: program,
    groups: groups,
    saveText: "Submit",
    handleCancel: handleClose,
  };

  return (
    <YarsDialog
      open={open}
      aria-labelledby="program-denial-dialog-title"
      aria-describedby="program-denial-dialog-content"
    >
      <YarsDialogTitle id="program-denial-dialog-title">
        Program Denial Reason
        <CloseDialogButton onClose={handleClose} />
      </YarsDialogTitle>
      <YarsDialogContent>
        <Typography sx={{ marginBottom: "1.5rem" }}>
          Include all reasons for denying this program, and include possible
          changes that will make the program acceptable.
        </Typography>
        <FormGenerator schema={schema} />
      </YarsDialogContent>
    </YarsDialog>
  );
};

export default ProgramDenialDialog;
