import { DateTime } from "luxon";

/**
 * Get a string date in mm/dd/yyyy format for exporting to CSV
 */
export function csvDateFormat(date: Date) {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT);
}

/**
 * Get a string yes or no based on boolean for exporting to CSV
 */
export function csvBooleanText(value: boolean): string {
  if (value) {
    return "Yes";
  }

  return "No";
}
