import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, CSSProperties, useState } from "react";
import sponsorUnitClient from "../../api/sponsorUnitClient";
import { useSessionContext } from "../../context/SessionContext";
import { SponsorUnit } from "../../sponsor-units/types";
import AddSponsorUnitDialog from "../AddSponsorUnitDialog";

interface SponsorUnitPickerProps {
  onSponsorUnitChange: (sponsorUnit: SponsorUnit | null) => void;
  defaultValue?: SponsorUnit;
  customStyle?: CSSProperties;
  label?: string;
}

function SponsorUnitPicker(props: SponsorUnitPickerProps) {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [options, setOptions] = useState<SponsorUnit[]>([]);
  const [selected, setSelected] = useState<SponsorUnit | null>(
    props.defaultValue ?? null
  );
  const [loading, setLoading] = React.useState(false);
  const session = useSessionContext();

  const canAddSponsorUnit = session.user?.permissions.includes(
    "sponsor_units.add_sponsorunit"
  );

  /**
   * If not currently loading get, and set the sponsor units.
   */
  React.useEffect(() => {
    if (loading) {
      sponsorUnitClient
        .getAll()
        .then((sponsorUnits) => setOptions(sponsorUnits))
        .catch((e: Error) => {
          console.error(`Unable to retrieve years: ${e.message}`);
        })
        .finally(() => setLoading(false));
    }
  }, [loading]);

  /**
   * When the select is closed set the options to null.
   */
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  function handleDialogSubmit(sponsorUnit: SponsorUnit) {
    setSelected(sponsorUnit);
    props.onSponsorUnitChange(sponsorUnit);
    setDialogOpen(false);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function handleChange(e: ChangeEvent<any>, value: SponsorUnit | null) {
    setSelected(value);
    props.onSponsorUnitChange(value);
  }

  return (
    <>
      <AddSponsorUnitDialog
        open={dialogOpen}
        title="Add Sponsor Unit"
        handleClose={handleDialogClose}
        handleSubmit={handleDialogSubmit}
      />

      <Grid container>
        <Grid item xs={12} md={canAddSponsorUnit ? 10 : 12}>
          <Autocomplete
            open={open}
            id="sponsor-unit-picker"
            onOpen={() => {
              setOpen(true);
              setLoading(true);
            }}
            onClose={() => {
              setOpen(false);
              setLoading(false);
            }}
            value={selected}
            onChange={handleChange}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={options}
            loading={loading}
            style={props.customStyle}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.label || "Sponsoring Unit"}
                fullWidth
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        {canAddSponsorUnit && (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="flex-start"
            md={2}
          >
            <Button
              className="secondary-action-btn"
              aria-label="Open Create Sponsor Unit Dialog"
              aria-haspopup="true"
              onClick={() => setDialogOpen(true)}
            >
              New Sponsor Unit
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default SponsorUnitPicker;
