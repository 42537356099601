import { Route, Switch, useRouteMatch } from "react-router";
import PageNotFound from "../components/PageNotFound";
import SponsorUnitDetail from "./SponsorUnitDetail";
import SponsorUnitEdit from "./SponsorUnitEdit";
import SponsorUnitsList from "./SponsorUnitsList";

/**
 * Define routing for /sponsor-units endpoint
 */
function SponsorUnits() {
  const match = useRouteMatch();

  if (match === null) {
    throw new Error("Sponsor units route not matched");
  }

  return (
    <Switch>
      <Route exact path={match.path}>
        <SponsorUnitsList />
      </Route>
      <Route exact path={`${match.path}/create`}>
        <SponsorUnitEdit />
      </Route>
      <Route path={`${match.path}/:sponsorUnitSlug/edit`}>
        <SponsorUnitEdit />
      </Route>
      <Route path={`${match.path}/:sponsorUnitSlug`}>
        <SponsorUnitDetail />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default SponsorUnits;
