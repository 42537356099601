import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import { Notification as NotificationType } from "../common/types";
import { useNotificationContext } from "../context/NotificationContext";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function Notification() {
  const { notification, setNotification } = useNotificationContext();
  const className = notification.type || "info";

  const Icon = variantIcon[className];

  function handleClose() {
    setNotification({
      message: "",
      type: notification.type,
    } as NotificationType);
  }

  function getBackgroundColor() {
    let backgroundColor;

    switch (notification.type) {
      case "success":
        backgroundColor = "#006469";
        break;
      case "warning":
        backgroundColor = "#AC5F06";
        break;
      case "error":
        backgroundColor = "#990033";
        break;
      default:
        // Default to info color
        backgroundColor = "#003e6b";
    }

    return backgroundColor;
  }

  return (
    <Snackbar
      aria-live="assertive"
      open={notification.message ? true : false}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={handleClose}
      autoHideDuration={4000}
    >
      <SnackbarContent
        sx={{ backgroundColor: getBackgroundColor() }}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={className}>
            <Icon />
            {notification.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            className="closeSnackbar"
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

export default Notification;
