import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

type FormButtonsProps = {
  /** isSubmitting state to disable save button while form is being submitted */
  isSubmitting: boolean;
  /** Text for the save button */
  saveText?: string | undefined;
  /** Is save button disabled */
  saveDisabled?: boolean | undefined;
  /** If buttons are in a dialog handle close instead of going back in history */
  handleCancel?: () => void;
};

function FormButtons(props: FormButtonsProps) {
  const { isSubmitting, saveDisabled } = props;
  const history = useHistory();

  function handleCancel() {
    if (!props.handleCancel) {
      history.goBack();
    } else {
      props.handleCancel();
    }
  }

  return (
    <Box m={2}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Button
            className="secondary-action-btn"
            onClick={handleCancel}
            size="medium"
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            aria-label={props.saveText === undefined ? "Save" : props.saveText}
            className="primary-action-btn"
            type="submit"
            variant="contained"
            size="medium"
            disabled={isSubmitting || saveDisabled}
          >
            {props.saveText === undefined ? "Save" : props.saveText}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FormButtons;
