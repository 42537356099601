import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiError } from "../../api/errors";
import sessionClient from "../../api/sessionClient";
import ErrorMessage from "../../components/ErrorMessage";
import Page from "../../components/Page";
import { Session } from "../types";
import ParticipantForm from "./ParticipantForm";

function ParticipantEdit() {
  const [session, setSession] = useState<Session>({} as Session);
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { sessionSlug } = useParams<{ sessionSlug: string }>();

  useEffect(() => {
    if (sessionSlug) {
      sessionClient
        .getBySlug(sessionSlug)
        .then((sessionResponse) => setSession(sessionResponse))
        .catch((e: ApiError) => setApiError(e));
    }
  }, [sessionSlug]);

  return (
    <Page>
      {apiError ? (
        <ErrorMessage message="No Session Found" />
      ) : session.id ? (
        <ParticipantForm session={session} />
      ) : (
        <>Loading...</>
      )}
    </Page>
  );
}

export default ParticipantEdit;
