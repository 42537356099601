import { Person, PersonJson } from "../people/types";
import { Program, ProgramJson } from "../programs/types";

export interface SessionJson {
  id: number;
  slug: string;
  program: ProgramJson | number;
  description?: string | null;
  name: string;
  director?: PersonJson | number;
  date_confirmed?: boolean;
  primary_location?: string;
  primary_overnight_location?: string;
  age_range?: string;
  max_participants?: number;
  waitlist?: YesNo;
  approval_status?: string;
  run_status: string;
  type_code?: string;
  type_info?: string;
  type_display?: string;
  registered_student_org_type?: string;
  approval_source?: string;
  approval_type?: string;
  approval_notes?: string;
  custodial_care?: string;
  custodial_care_display?: string;
  youth_supervision_plan?: string;
  health_and_safety_plan?: string;
  emergency_plan?: string;
  escalation_matrix?: string;
  start_date?: string;
  end_date?: string;
  exclude_from_integrations?: boolean;
  participant_count?: number;
  staff_count?: number;
  staff_background_checks?: boolean;
  staff_required_training?: boolean;
  staff_orientation?: boolean;
  staff_youngest_age?: number;
  is_compliant?: boolean;
  created_at?: string;
  updated_at?: string;
  forms_due?: string;
  forms_close?: string;
  registration_open?: string;
  registration_close?: string;
  fee?: number;
  deposit?: number;
}

export interface Session {
  id: number;
  slug: string;
  program: Program | number;
  name: string;
  description?: string | null;
  director?: Person | number;
  dateConfirmed?: boolean;
  primaryLocation?: string;
  primaryOvernightLocation?: string;
  ageRange?: string;
  maxParticipants?: number;
  waitlist?: YesNo;
  approvalStatus?: string;
  runStatus: string;
  typeCode?: string;
  typeInfo?: string;
  typeDisplay?: string;
  registeredStudentOrgType?: string;
  approvalSource?: string;
  approvalType?: string;
  approvalNotes?: string;
  custodialCare?: string;
  custodialCareDisplay?: string;
  youthSupervisionPlan?: string;
  healthAndSafetyPlan?: string;
  emergencyPlan?: string;
  escalationMatrix?: string;
  startDate?: Date;
  endDate?: Date;
  excludeFromIntegrations?: boolean;
  participantCount?: number;
  staffCount?: number;
  staffBackgroundChecks?: boolean;
  staffRequiredTraining?: boolean;
  staffOrientation?: boolean;
  staffYoungestAge?: number;
  isCompliant?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  formsDue?: Date;
  formsClose?: Date;
  registrationOpen?: Date;
  registrationClose?: Date;
  fee?: number;
  deposit?: number;
}

export interface FormMenuItem {
  val?: string;
  text?: string;
}

export type YesNo = "y" | "n" | "na";

export const typeItems: FormMenuItem[] = [
  { val: "com", text: "Commuter" },
  { val: "res", text: "Residential" },
  { val: "rfc", text: "Residential for Credit" },
  { val: "rso", text: "Registered Student Organization" },
  { val: "ft", text: "Field Trip" },
  { val: "ind", text: "Individual" },
  { val: "oth", text: "Other" },
];

export const custodialCareItems: FormMenuItem[] = [
  { val: "uw", text: "UW Institution" },
  { val: "ap", text: "Affiliated Program" },
  { val: "tp", text: "Third Party" },
  { val: "ft", text: "Field Trip Organizer" },
  { val: "hy", text: "Hybrid (Defined in Contract)" },
];

export const yesNoItems: FormMenuItem[] = [
  { val: "na", text: "N/A" },
  { val: "y", text: "Yes" },
  { val: "n", text: "No" },
];

export const ageRangeItems: FormMenuItem[] = [
  { val: "e", text: "Elementary" },
  { val: "m", text: "Middle School" },
  { val: "h", text: "High School" },
];

export const orgTypeItems: FormMenuItem[] = [
  { val: "co", text: "University Co-Sponsored" },
  { val: "tp", text: "Third Party" },
];
