import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { ApiError } from "../api/errors";
import sponsorUnitClient from "../api/sponsorUnitClient";
import ErrorMessage from "../components/ErrorMessage";
import Page from "../components/Page";
import SponsorUnitForm from "./SponsorUnitForm";
import { SponsorUnit } from "./types";

function SponsorUnitView() {
  const [sponsorUnit, setSponsorUnit] = useState<SponsorUnit>(
    {} as SponsorUnit
  );
  const [apiError, setApiError] = useState<ApiError>({} as ApiError);

  const match = useRouteMatch("/sponsor-units/:sponsorUnitSlug/edit");

  const { sponsorUnitSlug } = useParams<{ sponsorUnitSlug: string }>();

  useEffect(() => {
    if (sponsorUnitSlug) {
      sponsorUnitClient
        .getBySlug(sponsorUnitSlug)
        .then((sponsorUnitResponse) => setSponsorUnit(sponsorUnitResponse))
        .catch((e: ApiError) => setApiError(e));
    }
  }, [sponsorUnitSlug]);

  return (
    <Page>
      {apiError.message ? (
        <ErrorMessage message="No Sponsor Unit Found" />
      ) : match ? (
        sponsorUnit.name && <SponsorUnitForm sponsorUnit={sponsorUnit} />
      ) : (
        <SponsorUnitForm />
      )}
    </Page>
  );
}

export default SponsorUnitView;
