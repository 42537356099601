import React, { useContext, useState } from "react";
import { User } from "../auth/types";

/** A logged-in user, their attributes, and their session information */
export interface Session {
  user?: User;
  setUser: (user?: User) => void;
  clearUser: () => void;
  isReady: boolean; // true if the session's state is ready to be evaluated
}

interface SessionProviderProps {
  children?: React.ReactNode;
  user?: User;
}

/** A context which provides the currently logged in user */
export const SessionContext = React.createContext<Session>({
  setUser: () => {
    throw new Error("SessionContext provider not found");
  },
  clearUser: () => {
    throw new Error("SessionContext provider not found");
  },
  isReady: false,
});

export function SessionProvider(props: SessionProviderProps) {
  const [user, setUser] = useState<User | undefined>(props.user);
  const [isReady, setIsReady] = useState<boolean>(false);

  const session: Session = {
    user: user,
    setUser: (user) => {
      setUser(user);
      setIsReady(true);
    },
    clearUser: () => {
      setUser(undefined);
      setIsReady(true);
    },
    isReady: isReady,
  };

  return (
    <SessionContext.Provider value={session}>
      {props.children}
    </SessionContext.Provider>
  );
}

export const useSessionContext = () => useContext(SessionContext);
