import { useEffect } from "react";
import { authClient } from "../api/authClient";
import { useSessionContext } from "../context/SessionContext";

/**
 * A high-level component that checks if a user has valid tokens and sets the application's session context appropriately.
 */
export const Session = () => {
  const session = useSessionContext();

  /** When app is first loaded (or session has changed), check to see if the user can access the backend */
  useEffect(() => {
    if (!session.isReady) {
      authClient
        .getMe()
        .then((user) => session.setUser(user))
        .catch(() => session.clearUser());
    }
  }, [session]);

  return <></>;
};

export default Session;
