import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import { date, number, object, ref, string } from "yup";
import { ApiError } from "../api/errors";
import programClient from "../api/programClient";
import sessionClient from "../api/sessionClient";
import { DEFAULT_ERROR_NOTIFICATION } from "../common/constants";
import { Redirect as RedirectType } from "../common/types";
import FormGenerator, {
  Field,
  FormSchema,
  Group,
  WrapperProps,
} from "../components/forms/FormGenerator";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";
import { Program } from "../programs/types";
import {
  custodialCareItems,
  orgTypeItems,
  Session,
  typeItems,
  yesNoItems,
} from "./types";

type SessionFormProps = {
  session?: Session;
};

function SessionForm(props: SessionFormProps) {
  const [session] = useState<Partial<Session>>(
    props.session || {
      name: "",
      maxParticipants: 0,
      runStatus: "pen",
      typeCode: "",
      custodialCare: "",
      youthSupervisionPlan: "",
      healthAndSafetyPlan: "",
      emergencyPlan: "",
      escalationMatrix: "",
    }
  );
  const [program, setProgram] = useState<Program>({} as Program);
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });
  const { setNotification } = useNotificationContext();

  const { programSlug } = useParams<{ programSlug: string }>();

  useEffect(() => {
    if (programSlug) {
      programClient
        .getBySlug(programSlug)
        .then((programResponse: Program) => {
          setProgram(programResponse);
        })
        .catch((e: Error) => {
          console.error(`Unable to load program ${program.name}: ${e.message}`);
        });
    }
  }, [programSlug]);

  function handleSubmit(
    session: Partial<Session>,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    if (session.id) {
      sessionClient
        .update(session)
        .then((sessionResponse) => {
          setNotification({
            message: "Session Updated!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri:
              "/programs/" +
              program.slug +
              "/sessions/" +
              sessionResponse.slug +
              "/",
          });
        })
        .catch((e: Error) => {
          if (e instanceof ApiError && e.fieldErrors) {
            const errors = e.fieldErrors;
            for (const [_, errorMessage] of Object.entries(errors)) {
              setNotification({
                message: errorMessage,
                type: "error",
              });
            }
          } else {
            console.error(
              `Unable to update session ${session.id ?? ""}: ${e.message}`
            );
            setNotification({
              message: DEFAULT_ERROR_NOTIFICATION,
              type: "error",
            });
          }
          setSubmitting(false);
        });
    } else {
      session.program = program.id;
      sessionClient
        .create(session)
        .then((response) => {
          setNotification({
            message: "Session Created!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri:
              "/programs/" + program.slug + "/sessions/" + response.slug + "/",
          });
        })
        .catch((e: Error) => {
          console.error("Unable to create session: " + e.message);
          setNotification({
            message: DEFAULT_ERROR_NOTIFICATION,
            type: "error",
          });
          setSubmitting(false);
        });
    }
  }

  const validationSchema = object().shape(
    {
      name: string().trim().required("Session Name is Required"),
      maxParticipants: number().required(
        "Maximum number of participants is Required"
      ),
      typeCode: string(),
      primaryOvernightLocation: string().when("typeCode", {
        is: "res",
        then: string().required(
          'Primary Overnight Location is required when "Residential" type is selected'
        ),
        otherwise: string().nullable(),
      }),
      typeInfo: string().when("typeCode", {
        is: "oth",
        then: string()
          .trim()
          .required('Type Info is required when "Other" type is selected'),
        otherwise: string().nullable(),
      }),
      registeredStudentOrgType: string().when("typeCode", {
        is: "rso",
        then: string().required(
          'Registered Student Organization Type is required when "Registered Student Organization" type is selected'
        ),
        otherwise: string().nullable(),
      }),
      startDate: date()
        .required("A start date is required")
        .when("endDate", {
          is: (endDate: Date) => endDate !== undefined,
          then: date().max(
            ref("endDate"),
            "Start Date must be before End Date"
          ),
        }),
      endDate: date()
        .required("An end date is required")
        .when("startDate", {
          is: (startDate: Date) => startDate !== undefined,
          then: date().min(
            ref("startDate"),
            "End Date must be after Start Date"
          ),
        }),
      formsDue: date()
        .required("Forms Submission Due Date is required")
        .when("startDate", {
          is: (startDate: Date) => startDate !== undefined,
          then: date().max(
            ref("startDate"),
            "Forms Submission Due Date must be before Start Date"
          ),
        }),
      formsClose: date()
        .required("Last Day To Update Date is required")
        .when("startDate", {
          is: (startDate: Date) => startDate !== undefined,
          then: date().max(
            ref("startDate"),
            "Last Day To Update Date must be before Start Date"
          ),
        }),
      registrationOpen: date()
        .required("Registration Open Date is required")
        .when("startDate", {
          is: (startDate: Date) => startDate !== undefined,
          then: date().max(
            ref("startDate"),
            "Registration Open Date must be before Start Date"
          ),
        }),
      registrationClose: date()
        .required("Registration Deadline Date is required")
        .when("startDate", {
          is: (registrationOpen: Date) => registrationOpen !== undefined,
          then: date().min(
            ref("registrationOpen"),
            "Registration Deadline Date must be after Registration Open Date"
          ),
        }),
      fee: number().required("Fee amount is Required"),
      deposit: number().required("Deposit number is Required"),
    },
    [["startDate", "endDate"]]
  );

  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  const fields: Field[] = [
    {
      name: "name",
      prettyName: "Name",
      ariaLabel: "name",
      type: "text",
      required: true,
    },
    {
      name: "description",
      prettyName: "Description",
      ariaLabel: "description",
      type: "text",
      required: false,
      fieldProps: {
        multiline: true,
      },
    },
    {
      name: "maxParticipants",
      prettyName: "What is the maximum number of participants?",
      type: "number",
      ariaLabel: "Maximum number of participants",
      required: true,
    },
    {
      name: "custodialCare",
      prettyName: "Who Takes Custodial Care",
      type: "select",
      required: false,
      ariaLabel: "custodial care",
      items: custodialCareItems,
    },
    {
      name: "director",
      prettyName: "Session Director",
      type: "personSelect",
      ariaLabel: "session director",
      required: false,
    },
    {
      name: "waitlist",
      prettyName: "Is there a waitlist?",
      type: "select",
      required: false,
      ariaLabel: "Waitlist",
      items: yesNoItems,
    },
  ];

  const planFields: Field[] = [
    {
      name: "youthSupervisionPlan",
      prettyName: "Youth Supervision Plan Submitted",
      type: "select",
      required: false,
      ariaLabel: "youth supervision plan",
      items: yesNoItems,
    },
    {
      name: "healthAndSafetyPlan",
      prettyName: "Health And Safety Plan Submitted",
      type: "select",
      required: false,
      ariaLabel: "health and safety plan",
      items: yesNoItems,
    },
    {
      name: "emergencyPlan",
      prettyName: "Emergency Plan Submitted",
      type: "select",
      required: false,
      ariaLabel: "emergency plan",
      items: yesNoItems,
    },
    {
      name: "escalationMatrix",
      prettyName: "Escalation Matrix Submitted",
      type: "select",
      required: false,
      ariaLabel: "escalation matrix",
      items: yesNoItems,
    },
  ];

  const typeFields: Field[] = [
    {
      name: "typeCode",
      prettyName: "Type",
      type: "select",
      required: false,
      ariaLabel: "type",
      items: typeItems,
    },
    {
      name: "primaryOvernightLocation",
      prettyName: "Primary Overnight Location",
      type: "text",
      required: true,
      ariaLabel: "primary overnight location",
      conditional: { field: "typeCode", is: "res" },
    },
    {
      name: "typeInfo",
      prettyName: "Type Info",
      type: "text",
      required: true,
      ariaLabel: "type info",
      conditional: { field: "typeCode", is: "oth" },
    },
    {
      name: "registeredStudentOrgType",
      prettyName: "Registered Student Organization Type",
      type: "select",
      required: true,
      ariaLabel: "registered student organization type",
      conditional: { field: "typeCode", is: "rso" },
      items: orgTypeItems,
    },
    {
      name: "primaryLocation",
      prettyName: "Primary Location",
      type: "text",
      ariaLabel: "primary location",
      required: false,
    },
  ];

  const dateFields: Field[] = [
    {
      name: "startDate",
      prettyName: "Start Date",
      type: "date",
      ariaLabel: "start date",
      required: true,
    },
    {
      name: "endDate",
      prettyName: "End Date",
      type: "date",
      ariaLabel: "end date",
      required: true,
    },
    {
      name: "registrationOpen",
      prettyName: "First day to register for session",
      type: "date",
      ariaLabel: "First Day To Register",
      required: true,
    },
    {
      name: "registrationClose",
      prettyName: "Last day to register for session",
      type: "date",
      ariaLabel: "Last Day To Register",
      required: true,
    },
    {
      name: "formsDue",
      prettyName: "Participant forms submission due date",
      type: "date",
      ariaLabel: "Participant forms due date",
      required: true,
    },
    {
      name: "formsClose",
      prettyName: "Last date for participant forms to be updated",
      type: "date",
      ariaLabel: "Last date for participant forms to be updated",
      required: true,
    },
    {
      name: "fee",
      prettyName: "Fee",
      type: "number",
      ariaLabel: "fee",
      required: true,
    },
    {
      name: "deposit",
      prettyName: "Deposit",
      type: "number",
      ariaLabel: "Deposit",
      required: true,
    },
  ];

  const HalfWrapper = (props: WrapperProps) => (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" component="h3" style={{ marginBottom: 10 }}>
        {props.prettyName}
      </Typography>
      {props.children}
    </Grid>
  );

  const FullWrapper = (props: WrapperProps) => (
    <Grid item xs={12}>
      <Typography variant="h6" component="h3" style={{ marginBottom: 10 }}>
        {props.prettyName}
      </Typography>
      {props.children}
    </Grid>
  );

  const groups: Group[] = [
    {
      name: "session-information-form-group",
      prettyName: "Session Information",
      fields: fields,
      wrapper: FullWrapper,
    },
    {
      name: "session-submission-form-group",
      prettyName: "Session Plans",
      fields: planFields,
      wrapper: HalfWrapper,
    },
    {
      name: "session-type-form-group",
      prettyName: "Location & Type Information",
      fields: typeFields,
      wrapper: HalfWrapper,
    },
    {
      name: "session-date-form-group",
      prettyName: "Date Information",
      fields: dateFields,
      wrapper: HalfWrapper,
    },
  ];

  const formSchema: FormSchema<Partial<Session>> = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: session,
    groups: groups,
  };

  return (
    <Card aria-label="new session form" className="uw-card">
      <YarsCardHeader
        component="h2"
        title={
          session.name
            ? `Edit ${session.name || ""} Session`
            : "Create New Session"
        }
      />
      <CardContent>
        <FormGenerator schema={formSchema} />
      </CardContent>
    </Card>
  );
}

export default SessionForm;
