import PersonForm from "../people/PersonForm";
import { Person } from "../people/types";
import {
  CloseDialogButton,
  YarsDialog,
  YarsDialogContent,
  YarsDialogTitle,
} from "./YarsDialog";

type AddPersonDialogProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleSubmit: (person: Person) => void;
};

/**
 * Dialog to create a person used if a person does not exist within the person dialog.
 */
function AddPersonDialog(props: AddPersonDialogProps) {
  const { open, handleSubmit, handleClose, title } = props;
  return (
    <YarsDialog
      open={open}
      aria-labelledby="add-person-dialog-title"
      aria-describedby="add-person-dialog-content"
    >
      <YarsDialogTitle id="add-person-dialog-title">
        {title}
        <CloseDialogButton onClose={handleClose} />
      </YarsDialogTitle>
      <YarsDialogContent id="add-person-dialog-content">
        <PersonForm
          isDialog={true}
          showHeader={true}
          onSubmit={handleSubmit}
          saveText="Add Person"
          aria-label="Add new person"
          handleDialogClose={handleClose}
        />
      </YarsDialogContent>
    </YarsDialog>
  );
}

export default AddPersonDialog;
