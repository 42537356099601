import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import YarsCardHeader from "../components/YarsCardHeader";
import NavLinkForward from "../navigation/NavLinkForward";
import { isOversightOffice, OversightOffice } from "../oversight-offices/types";
import { isPerson, Person } from "../people/types";
import { Program } from "../programs/types";
import { SponsorUnit } from "./types";

function SponsorUnitInfoCard(props: {
  sponsorUnit?: SponsorUnit;
  program?: Program;
}) {
  const sponsorUnit = props.sponsorUnit;
  const program = props.program;

  let action: ReactElement = <></>;
  let resPerson: Person | undefined = undefined;
  let oversightOffice: OversightOffice | undefined = undefined;
  let oversightPerson: Person | undefined = undefined;
  let button: ReactElement = <></>;

  if (sponsorUnit) {
    action = (
      <IconButton
        aria-label="Edit Sponsoring Unit Details"
        component={NavLinkForward}
        to={`/sponsor-units/${sponsorUnit.slug}/edit`}
        size="large"
      >
        <ArrowForwardIosIcon />
      </IconButton>
    );
    if (sponsorUnit.resPerson && isPerson(sponsorUnit.resPerson)) {
      resPerson = sponsorUnit.resPerson;
    }
    if (
      sponsorUnit.oversightOffice &&
      isOversightOffice(sponsorUnit.oversightOffice)
    ) {
      oversightOffice = sponsorUnit.oversightOffice;
      if (
        oversightOffice.oversightPerson &&
        isPerson(oversightOffice.oversightPerson)
      ) {
        oversightPerson = oversightOffice.oversightPerson;
      }
    }
  }

  if (!program && sponsorUnit) {
    button = (
      <Button
        className="primary-action-btn"
        component={NavLinkForward}
        to={`/sponsor-units/${sponsorUnit.slug}/edit`}
        size="medium"
        variant="outlined"
      >
        Edit Sponsoring Unit
      </Button>
    );
  }

  if (program && program.permissions?.includes("change_program")) {
    button = (
      <Button
        className="secondary-action-btn"
        component={NavLinkForward}
        to={`/programs/${program.slug}/edit`}
        size="medium"
        variant="outlined"
      >
        Assign Sponsoring Unit
      </Button>
    );
  }

  return (
    <Card
      variant="outlined"
      aria-label="Sponsor unit details"
      className="uw-card-details"
    >
      <YarsCardHeader
        title="Sponsoring Unit Details"
        action={program ? action : <></>}
      />
      <CardContent>
        {sponsorUnit ? (
          <List dense={true} className="details-list">
            <ListItem aria-labelledby="sponsorUnitName">
              <ListItemAvatar>
                <Avatar>
                  <AccountBalanceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id="sponsorUnitName"
                primary="Name"
                secondary={sponsorUnit.name}
              />
            </ListItem>
            <ListItem
              aria-labelledby="RESpersonName"
              component={NavLinkForward}
              to={resPerson ? `/people/${resPerson.slug ?? ""}/edit` : ""}
              disabled={Boolean(!resPerson)}
            >
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id="RESpersonName"
                primary="Dean or Director"
                secondary={resPerson ? resPerson.fullName : "Not assigned"}
              />
            </ListItem>
            <ListItem
              aria-labelledby="oversightPersonName"
              component={NavLinkForward}
              to={oversightPerson ? `/people/${oversightPerson.slug}/edit` : ""}
              disabled={Boolean(!oversightPerson)}
            >
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id="oversightPersonName"
                primary="School or Department"
                secondary={
                  oversightPerson ? oversightPerson.fullName : "Not assigned"
                }
              />
            </ListItem>
            <ListItem
              aria-labelledby="oversightOfficeName"
              disabled={Boolean(!oversightOffice)}
            >
              <ListItemAvatar>
                <Avatar>
                  <BusinessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id="oversightOfficeName"
                primary="Oversight Office"
                secondary={
                  oversightOffice ? oversightOffice.name : "Not assigned"
                }
              />
            </ListItem>
          </List>
        ) : (
          <Typography>
            A sponsoring unit is not assigned to this program
          </Typography>
        )}
      </CardContent>
      <CardActions>{button}</CardActions>
    </Card>
  );
}

export default SponsorUnitInfoCard;
