import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { ApiError } from "../api/errors";
import programClient from "../api/programClient";
import ErrorMessage from "../components/ErrorMessage";
import YarsCardHeader from "../components/YarsCardHeader";
import NavLinkForward from "../navigation/NavLinkForward";
import { Session } from "../sessions/types";
import { Program } from "./types";

/**
 * Return an array of a program's session start dates, or an empty array if it has none.
 * @param program Program
 * @returns Array<Program>
 */
const getSessionStartDates = (program: Program) =>
  program.sessions?.reduce((dates: DateTime[], session: Session) => {
    if (session.startDate) {
      return [...dates, DateTime.fromJSDate(session.startDate)];
    }
    return dates;
  }, []) || [];

/**
 * Return an array of Programs, sorted by the earliest Session start dates.
 * @param programs Array<Program>
 * @returns Array<Program>
 */
export const sortByEarliestSession = (programs: Array<Program>) => {
  return programs.sort((a: Program, b: Program) => {
    const aStartDates = getSessionStartDates(a);
    const bStartDates = getSessionStartDates(b);
    if (aStartDates.length && bStartDates.length) {
      // Get the earliest session date for both programs and return the difference
      // (negative is earlier, 0 is equivalent, positive is later)
      const aEarliestDate = DateTime.min(...aStartDates);
      const bEarliestDate = DateTime.min(...bStartDates);
      return aEarliestDate.toUnixInteger() - bEarliestDate.toUnixInteger();
    } else if (aStartDates.length) {
      // "a" is earlier if it has sessions and "b" doesn't
      return -1;
    } else {
      // "b" is earlier if it has sessions and "a" doesn't
      return 1;
    }
  });
};

function ProgramsSubmittedList() {
  const [programs, setPrograms] = useState<Array<Program>>([]);
  const [totalSubmitted, setTotalSubmitted] = useState(0);
  const [apiError, setApiError] = useState<ApiError>({} as ApiError);

  useEffect(() => {
    programClient
      .getByStatus("submitted")
      .then((programs) => {
        setTotalSubmitted(programs.length);
        const sorted = sortByEarliestSession(programs);
        // Get the first five programs
        setPrograms(sorted.slice(0, 5));
      })
      .catch((e: ApiError) => setApiError(e));
  }, [setPrograms, setTotalSubmitted]);

  const earliestSessionDate = (program: Program) => {
    if (program.sessions) {
      const sessionsStartDates = getSessionStartDates(program);
      const earliestDateTime = DateTime.min(...sessionsStartDates);
      if (earliestDateTime) {
        const startDate =
          earliestDateTime &&
          earliestDateTime.toLocaleString(DateTime.DATE_FULL);
        return `First session begins on ${startDate}`;
      } else {
        return "Program doesn't have any sessions yet";
      }
    }
  };

  return (
    <Card
      variant="outlined"
      aria-label="List of Programs Awaiting Review"
      className="uw-card-details"
    >
      <YarsCardHeader title="Programs Awaiting Review" component="h2" />

      <CardContent>
        <p>
          These programs have been submitted in YARS and are waiting for your
          review.
        </p>
        {apiError.message ? (
          <ErrorMessage message="Unable to Fetch Submitted Programs" />
        ) : !programs.length ? (
          <p>All caught up!</p>
        ) : (
          <>
            <List dense={true} className="details-list">
              {programs.map((program: Program) => {
                return (
                  <div key={program.id}>
                    <ListItem
                      component={NavLinkForward}
                      to={`/programs/${program.slug}`}
                    >
                      <ListItemText
                        secondary={
                          <>
                            {program.name}
                            <br />
                            <Typography component="span">
                              {earliestSessionDate(program)}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  </div>
                );
              })}
            </List>
          </>
        )}
      </CardContent>
      <CardActions className="cardSingleAction">
        <Button href={`/programs?status=submitted`}>
          See all Submitted programs{totalSubmitted && ` (${totalSubmitted})`}
        </Button>
      </CardActions>
    </Card>
  );
}

export default ProgramsSubmittedList;
