import { Route, Switch, useRouteMatch } from "react-router";
import PageNotFound from "../components/PageNotFound";
import Profile from "./Profile";

/**
 * Defines routing for the /user endpoint
 */
export const Accounts = () => {
  const match = useRouteMatch();

  if (match === null) {
    throw new Error("User route not matched");
  }

  return (
    <Switch>
      <Route path={match.path}>
        <Switch>
          <Route exact path={match.path}>
            <Profile />
          </Route>
        </Switch>
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default Accounts;
