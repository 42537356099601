import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";

export default function Footer() {
  const theme = useTheme();
  const year = new Date(Date.now()).getFullYear();

  return (
    <Box
      component="footer"
      className="uw-footer"
      sx={{ zIndex: theme.zIndex.drawer + 1 }}
    >
      <Typography variant="body2" align="center">
        &copy; {year} Board of Regents of the University of Wisconsin System
      </Typography>
      <Typography variant="body2" align="center">
        Version: {process.env.REACT_APP_TAG || "N/A"}
      </Typography>
    </Box>
  );
}
