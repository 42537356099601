import { Grid, Link, Typography } from "@mui/material";
import { getEnv } from "../common/env";

function NotAuthorized() {
  const gridStyle = {
    paddingTop: "1.5rem",
  };

  const contact_email = getEnv("REACT_APP_CONTACT_EMAIL");

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={gridStyle}
    >
      <Grid item xs={12}>
        <Typography variant="h4" align="center" gutterBottom={true}>
          You are not authorized to view this application.
        </Typography>
        <Typography align="center">
          To request access, please email{" "}
          <Link href={`mailto:${contact_email}`}>{contact_email}</Link>.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default NotAuthorized;
