import { Typography } from "@mui/material";
import { DateTime } from "luxon";

type TimestampProps = {
  createdAt: Date;
  updatedAt: Date;
};

function Timestamp(props: TimestampProps) {
  return (
    <>
      <Typography align="center" variant="body2" component="p">
        Created:{" "}
        {DateTime.fromJSDate(props.createdAt).toLocaleString(
          DateTime.DATE_FULL
        )}
      </Typography>
      <Typography align="center" variant="body2" component="p">
        Updated:{" "}
        {DateTime.fromJSDate(props.createdAt).toLocaleString(
          DateTime.DATE_FULL
        )}
      </Typography>
    </>
  );
}

export default Timestamp;
