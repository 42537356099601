import { Group, GroupJson } from "../auth/types";
import { Person, PersonJson } from "../people/types";
import { Session, SessionJson } from "../sessions/types";
import { SponsorUnit, SponsorUnitJson } from "../sponsor-units/types";

/**
 * A program is an extracurricular activity that hosts youth.
 */
export interface Program {
  id: number;
  name: string;
  active?: boolean;
  status: Status;
  slug: string;
  sponsorUnit?: SponsorUnit | number;
  director?: Person | number;
  website?: string;
  sessions?: Session[];
  groups?: Array<Group>;
  permissions?: string[];
  createdAt?: Date;
  updatedAt?: Date;
  liaisonComments?: string;
}

/** Represents a Program in JSON from the backend API */
export interface ProgramJson {
  id: number;
  name: string;
  active?: boolean;
  status: Status;
  slug: string;
  sponsor_unit?: SponsorUnitJson | number;
  director?: PersonJson | number;
  website?: string;
  session_set?: SessionJson[];
  groups?: Array<GroupJson>;
  permissions?: string[];
  created_at?: string;
  updated_at?: string;
  liaison_comments?: string;
}

// Valid statuses for Programs
export type Status = "draft" | "submitted" | "reviewed" | "denied";

export function isProgram(program: Program | number): program is Program {
  return (program as Program).id !== undefined;
}

export function isProgramJson(
  program: ProgramJson | number
): program is ProgramJson {
  return (program as ProgramJson).id !== undefined;
}
