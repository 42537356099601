import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

/**
 * A "forward ref" of the React Router NavLink element. This is used when you want a NavLink as something other than an <a>
 *
 * The use of React.forwardRef will no longer be required for react-router-dom v6.
 * See https://github.com/ReactTraining/react-router/issues/6056
 */
export const NavLinkForward = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  function navLinkForward(props, ref) {
    return <NavLink innerRef={ref} {...props} />;
  }
);

export default NavLinkForward;
