import AccessibilityIcon from "@mui/icons-material/AccessibilityNew";
import QuestionMarkIcon from "@mui/icons-material/Help";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import SchoolIcon from "@mui/icons-material/School";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Page from "../components/Page";

/** Information on what support options are available to the user */
export default function Support() {
  const complianceIntegrityWebsite = "https://www.wisconsin.edu/compliance/";
  const youthProtectionWebsite =
    "https://www.wisconsin.edu/compliance/landing-page/child-safety/";
  const contactEmail =
    process.env.REACT_APP_CONTACT_EMAIL || "compliance@uwsa.edu";
  const accessibilityBarrierReportingForm =
    "https://docs.google.com/forms/d/e/1FAIpQLSedjL99RyKTO003uF4v4PkrFM_eEXqrwAeVEURV67l2uqhQfQ/viewform";
  const accessibilityUsabilityInformationDoc =
    "https://www.wisconsin.edu/compliance/landing-page/child-safety/youth-activities-registration-system/accessibility-usability-information/";

  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card
            aria-labelledby="infoCardAccessibility"
            className="featured-icon-card"
          >
            <CardContent>
              <AccessibilityIcon />
              <Typography
                id="infoCardAccessibility"
                variant="h5"
                component="h2"
              >
                Accessibility
              </Typography>
              <p>
                Report any accessibility barriers you encounter, by completing
                the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={accessibilityBarrierReportingForm}
                >
                  UW-Madison Accessibility Barrier Reporting Form
                </a>
                . For information about existing issues review the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={accessibilityUsabilityInformationDoc}
                >
                  Accessibility &amp; Usability Information{" "}
                </a>
                document.
              </p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card
            aria-labelledby="infoCardTraining"
            className="featured-icon-card"
          >
            <CardContent>
              <SchoolIcon />
              <Typography id="infoCardTraining" variant="h5" component="h2">
                Training
              </Typography>
              <p>
                The{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={complianceIntegrityWebsite}
                >
                  University of Wisconsin-System Office of Compliance &amp;
                  Integrity
                </a>{" "}
                supports the Youth Activity Registration System and provides
                compliance training.
              </p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card aria-labelledby="infoCardHelp" className="featured-icon-card">
            <CardContent>
              <QuestionMarkIcon />
              <Typography id="infoCardHelp" variant="h5" component="h2">
                Help and documentation
              </Typography>
              <p>
                If you have any training needs or questions, please email{" "}
                <a href={`mailto: ${contactEmail}`}>{contactEmail}</a>.
              </p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card
            aria-labelledby="infoCardResources"
            className="featured-icon-card"
          >
            <CardContent>
              <PermPhoneMsgIcon />
              <Typography id="infoCardResources" variant="h5" component="h2">
                Resources and reporting
              </Typography>
              <p>
                The{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={youthProtectionWebsite}
                >
                  Youth Protection website
                </a>{" "}
                offers compliance resources, as well as, methods for reporting
                abuse.
              </p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card
            aria-labelledby="infoCardMaintenance"
            className="featured-icon-card"
          >
            <CardContent>
              <NotificationImportantIcon />
              <Typography id="infoCardMaintenance" variant="h5" component="h2">
                Maintenance information
              </Typography>
              <p>
                The Youth Activity Registration System may be unavailable on
                Thursdays 7am to 9am for maintenance.
              </p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}
