import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { authClient } from "../api/authClient";
import { canSwitchOrgs, Organization } from "../auth/types";
import { useNotificationContext } from "../context/NotificationContext";
import { useSessionContext } from "../context/SessionContext";

export const COOKIE_ORG_ID = "session_org";
export const COOKIE_ORG_NAME = "session_org_name";
export const ORG_SWITCHER_LABEL = "Your Organization";

function OrgSwitcher() {
  const session = useSessionContext();
  const user = session.user;
  const defaultOrg = user?.organization.id;
  const { setNotification } = useNotificationContext();
  const [selectedOrg, setSelectedOrg] = useState<number | string>("");
  const [options, setOptions] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(true);
  const [switching, setSwitching] = useState(false);

  function handleChange(e: SelectChangeEvent) {
    if (!switching && canSwitchOrgs(user)) {
      setSwitching(true);
      const orgId = Number(e.target.value);
      authClient
        .switchOrganization(orgId)
        .then(() => {
          document.location.href = "/";
        })
        .catch((e: Error) => {
          console.error(`Unable to switch organizations: ${e.message}`);
          setNotification({
            message: "Unable to switch organizations",
            type: "error",
          });
        })
        .finally(() => {
          setSwitching(false);
        });
    }
  }

  React.useEffect(() => {
    if (!loading) {
      const cookie = Cookies.get(COOKIE_ORG_ID);
      if (cookie !== undefined) {
        setSelectedOrg(parseInt(cookie));
      } else if (user) {
        setSelectedOrg(user.organization.id);
      }
    }
  }, [user, loading]);

  /**
   * If not currently loading get, and set the people.
   */
  React.useEffect(() => {
    if (loading && canSwitchOrgs(user)) {
      authClient
        .getOrganizations()
        .then((organizations) => setOptions(organizations))
        .catch((e: Error) => {
          console.error(`Unable to fetch organizations: ${e.message}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, defaultOrg]);

  return (
    <>
      {canSwitchOrgs(user) && (
        <FormControl variant="standard">
          <InputLabel id="org-switcher-label">{ORG_SWITCHER_LABEL}</InputLabel>
          <Select
            aria-label="Select your organization"
            className="org-switcher-select"
            labelId="org-switcher-label"
            value={selectedOrg.toString()}
            onChange={handleChange}
            onOpen={() => setLoading(true)}
            onClose={() => setLoading(false)}
            inputProps={{
              name: "selected-org",
              id: "selected-org",
            }}
            style={{ marginBottom: "1rem" }}
          >
            {options.map((organization) => (
              <MenuItem
                className="org-selection"
                key={organization.id}
                value={organization.id}
              >
                {organization.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export default OrgSwitcher;
