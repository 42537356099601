import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { CSSProperties, SyntheticEvent, useEffect, useState } from "react";
import personClient from "../../api/personClient";
import { Person } from "../../people/types";
import AddPersonDialog from "../AddPersonDialog";

interface PersonPickerProps {
  label: string;
  onPersonChange: (person: Person | null) => void;
  defaultValue?: Person;
  customStyle?: CSSProperties;
  hideCreateButton?: boolean;
}

function PersonPicker({
  label,
  onPersonChange,
  defaultValue,
  customStyle,
  hideCreateButton,
}: PersonPickerProps) {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(
    defaultValue || null
  );
  const [options, setOptions] = useState<Person[]>([]);
  const [loading, setLoading] = useState(false);

  /**
   * If not currently loading, get and set the people.
   */
  useEffect(() => {
    if (loading) {
      personClient
        .getAll()
        .then((people) => setOptions(people))
        .catch((e: Error) => {
          console.error(`Unable to fetch people: ${e.message}`);
        })
        .finally(() => setLoading(false));
    }
  }, [loading]);

  /**
   * When the select is closed set the options to null.
   */
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  /**
   * On input change update state
   */
  function handleChange(
    _: SyntheticEvent<Element, Event>,
    value: Person | null
  ) {
    setSelectedPerson(value);
    onPersonChange(value);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function handleDialogSubmit(person: Person) {
    setSelectedPerson(person);
    onPersonChange(person);
    setDialogOpen(false);
  }

  return (
    <>
      <AddPersonDialog
        open={dialogOpen}
        title="Create New Person"
        handleClose={handleDialogClose}
        handleSubmit={handleDialogSubmit}
      />
      <Grid container>
        <Grid item xs={12} md={hideCreateButton ? 12 : 10}>
          <Autocomplete
            open={open}
            value={selectedPerson}
            id="person-picker"
            onOpen={() => {
              setOpen(true);
              setLoading(true);
            }}
            onClose={() => {
              setOpen(false);
              setLoading(false);
            }}
            onChange={(event, value) => handleChange(event, value)}
            isOptionEqualToValue={(option, value) => {
              if (value.id === option.id) {
                return true;
              }
              return false;
            }}
            getOptionLabel={(option) => {
              if (option.fullName) {
                return option.fullName;
              } else if (option.id && !option.fullName) {
                console.warn(`No full name for person with ID: ${option.id}`);
              }
              return "";
            }}
            options={options}
            loading={loading}
            style={customStyle}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                fullWidth
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        {!hideCreateButton && (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="flex-start"
            md={2}
          >
            <Button
              className="secondary-action-btn"
              aria-label="Open Create Person Dialog"
              aria-haspopup="true"
              onClick={() => setDialogOpen(true)}
            >
              New Person
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default PersonPicker;
