import Cookies from "js-cookie";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { COOKIE_ORG_NAME } from "../components/OrgSwitcher";
import { useSessionContext } from "./SessionContext";

type TitleContextProps = {
  appName: string;
  appNameShort: string;
  title: string;
  setTitle: (title: string) => void;
  subtitle: string;
  setSubtitle: (subtitle: string) => void;
};

type TitleProviderProps = {
  children?: React.ReactNode;
};

/**
 * Stores the current meta title and page headings
 *
 * The application uses this context to set the <title> tag, the <AppBar> title, and the headings in <PageHeadings>.
 * All pages should set appropriate titles with <WithTitle>
 *
 * appName:       Application name (read-only)
 * appNameShort:  Application name (shorthand, read-only)
 * title:         Main page heading
 * subtitle:      Secondary page heading
 */
const TitleContext = React.createContext<TitleContextProps>({
  appName: "",
  appNameShort: "",
  title: "",
  setTitle: () => {
    throw Error("No TitleContext provider defined");
  },
  subtitle: "",
  setSubtitle: () => {
    throw Error("No TitleContext provider defined");
  },
});

export function TitleProvider(props: TitleProviderProps) {
  const session = useSessionContext();
  const orgName =
    Cookies.get(COOKIE_ORG_NAME) || session?.user?.organization.name;
  const appName = "Youth Activity Registration System";
  const appNameWithOrg = orgName ? `${appName} - ${orgName}` : appName;
  const appNameShort = "YARS";
  const appNameShortWithOrg = orgName
    ? `${appNameShort} - ${orgName}`
    : appNameShort;

  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");

  // Set the Document Title (<title>) element
  useEffect(() => {
    const pageTitle = subtitle || title || "";
    document.title = pageTitle ? `${appNameShort} - ${pageTitle}` : appName;
  }, [title, subtitle]);

  return (
    <TitleContext.Provider
      value={{
        appName: appNameWithOrg,
        appNameShort: appNameShortWithOrg,
        title: title,
        setTitle: setTitle,
        subtitle: subtitle,
        setSubtitle: setSubtitle,
      }}
    >
      {props.children}
    </TitleContext.Provider>
  );
}

/**
 * A component that sets the title and/or subtitle when rendered.
 *
 * Example usage:
 *   <WithTitle title="Programs" subtitle={program.name}>
 *      <ProgramDetails program={program}/>
 *   </WithTitle>
 */
export const WithTitle: FunctionComponent<{
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
}> = (props) => {
  const { setTitle, setSubtitle } = useTitleContext();

  useEffect(() => {
    props.title !== undefined && setTitle(props.title);
    props.subtitle !== undefined && setSubtitle(props.subtitle);

    // After unmounting, clear the title and subtitle (if they were set)
    return () => {
      props.title && setTitle("");
      props.subtitle && setSubtitle("");
    };
  }, [props.title, props.subtitle]);

  return <>{props.children}</>;
};

export const useTitleContext = () => useContext(TitleContext);
export default TitleContext;
