import Cookies from "js-cookie";
import { Redirect, Route, RouteProps, Switch, useLocation } from "react-router";
import Accounts from "../accounts/Accounts";
import { Login } from "../auth/Login";
import Logout from "../auth/Logout";
import NotAuthorized from "../components/NotAuthorized";
import PageNotFound from "../components/PageNotFound";
import { useSessionContext } from "../context/SessionContext";
import { WithTitle } from "../context/TitleContext";
import Dashboard from "../dashboard/Dashboard";
import People from "../people/People";
import Programs from "../programs/Programs";
import Sessions from "../sessions/Sessions";
import SponsorUnits from "../sponsor-units/SponsorUnits";
import Support from "../support/Support";

/**
 * A collection of routes for the application
 *
 * Redirect handles case where there is a trailing slash.
 * If there is a trailing slash it will be removed.
 */
export const Routes = () => {
  const session = useSessionContext();
  const { pathname } = useLocation();

  // If the session is not yet ready for evaluation, do not attempt to make a routing decision
  if (!session.isReady) {
    return <></>;
  }

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <ProtectedRoute exact path="/">
        <WithTitle title="Dashboard">
          <Dashboard />
        </WithTitle>
      </ProtectedRoute>
      <ProtectedRoute path="/account">
        <WithTitle title="Account">
          <Accounts />
        </WithTitle>
      </ProtectedRoute>
      <ProtectedRoute path="/sponsor-units">
        <WithTitle title="Sponsoring Units">
          <SponsorUnits />
        </WithTitle>
      </ProtectedRoute>
      <ProtectedRoute path="/programs">
        <Programs />
      </ProtectedRoute>
      <ProtectedRoute path="/sessions">
        <Sessions />
      </ProtectedRoute>
      <ProtectedRoute path="/people">
        <WithTitle title="People">
          <People />
        </WithTitle>
      </ProtectedRoute>
      <ProtectedRoute path="/support">
        <WithTitle title="Support">
          <Support />
        </WithTitle>
      </ProtectedRoute>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/not-authorized">
        <NotAuthorized />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

/**
 * A route that requires a valid session, and redirects to the login page if there is none.
 *
 * Reference: https://reacttraining.com/react-router/web/example/auth-workflow
 */
function ProtectedRoute({
  children,
  ...props
}: RouteProps): React.ReactElement {
  const session = useSessionContext();

  if (
    session.user &&
    session.user.permissions.length === 0 &&
    session.user.groups.length === 0
  ) {
    return <NotAuthorized />;
  }

  if (!session.user) {
    const pathname = props.location?.pathname || "/";
    const search = props.location?.search || "";
    if (search != "?login_redirect=false") {
      Cookies.remove("login_redirect_location");
      Cookies.set("login_redirect_location", pathname + search);
    }
  }

  if (session.user) {
    return <Route {...props}>{children}</Route>;
  }

  return <Redirect to="/login" />;
}

export default Routes;
