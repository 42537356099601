import {
  Avatar,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import programClient from "../api/programClient";
import OrgSwitcher from "../components/OrgSwitcher";
import YarsCardHeader from "../components/YarsCardHeader";
import NavLinkForward from "../navigation/NavLinkForward";
import { Program } from "./types";

export const CARD_HEADER = "Current Stats";

/** A Program module for the Dashboard */
export const ProgramCard = () => {
  const [programs, setPrograms] = useState<Array<Program>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allProgramsCount, setAllProgramsCount] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    programClient
      .getActive()
      .then((programs) => {
        setAllProgramsCount(programs.length);
        setPrograms(programs);
      })
      .catch((e: Error) => {
        setError(true);
        console.error("Unable to fetch program count: " + e.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const activeProgramsCount = programs.filter((program) => {
    return program.active;
  });

  const deniedPrograms = programs.filter((program) => {
    return program.status === "denied";
  });

  return (
    <Card
      variant="outlined"
      aria-label="program dashboard module"
      className="uw-card"
    >
      <YarsCardHeader title={CARD_HEADER} component="h2" />
      <CardContent>
        <OrgSwitcher />
        {!isLoading && !error && (
          <List dense={true} className="details-list">
            <ListItem>
              <ListItemAvatar>
                <Avatar aria-labelledby="activeProgramsCount">
                  {activeProgramsCount.length}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id="activeProgramsCount"
                secondary="Active programs"
              />
            </ListItem>
            <ListItem component={NavLinkForward} to="/programs">
              <ListItemAvatar>
                <Avatar aria-labelledby="allProgramsCount">
                  {allProgramsCount}
                </Avatar>
              </ListItemAvatar>
              <ListItemText id="allProgramsCount" secondary="All programs" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar aria-labelledby="deniedProgramsCount">
                  {deniedPrograms.length}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id="deniedProgramsCount"
                secondary="Denied programs"
              />
            </ListItem>
          </List>
        )}
        {error && (
          <Typography color="error" align="center" role="alert">
            Could not retrieve programs
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ProgramCard;
