import LuxonAdapter from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { NoSsr } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import { SkipNavLink } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";
import { BrowserRouter } from "react-router-dom";
import IdleTimeout from "./auth/IdleTimeout";
import Session from "./auth/Session";
import Footer from "./components/Footer";
import Notification from "./components/Notification";
import { NotificationProvider } from "./context/NotificationContext";
import { SessionProvider } from "./context/SessionContext";
import { TitleProvider } from "./context/TitleContext";
import Main from "./navigation/Main";
import "./stylesheet/App.scss";

const theme = createTheme({
  mixins: {
    toolbar: {
      "@media (min-width: 0px)": {
        "@media (orientation: landscape)": {
          minHeight: 64, // override default landscape min-height of 48px for app bar spacer
        },
      },
    },
  },
});

/**
 * The root application component, defining the general layout of the site and routing.
 *
 * The layout is inspired by the Material-UI "Dashboard" app template:
 *   https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/dashboard
 */
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SessionProvider>
          <SkipNavLink>Skip to main content</SkipNavLink>
          <NotificationProvider>
            <TitleProvider>
              <LocalizationProvider dateAdapter={LuxonAdapter}>
                <NoSsr>
                  <IdleTimeout />
                  <Notification />
                  <Session />
                  <Box className="root">
                    <CssBaseline />
                    <Main />
                    <Footer />
                  </Box>
                </NoSsr>
              </LocalizationProvider>
            </TitleProvider>
          </NotificationProvider>
        </SessionProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
