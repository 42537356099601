/**
 * Defines interfaces/types for a person
 */

/**
 * A person can be any type of person in the app (res, volunteer, program director, etc.)
 */
export interface Person {
  id: number;
  givenName: string;
  surname?: string;
  slug: string;
  email: string;
  fullName: string;
  phoneNumber?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface PersonJson {
  id: number;
  given_name: string;
  surname?: string;
  slug: string;
  email: string;
  full_name: string;
  phone_number?: string;
  created_at?: string;
  updated_at?: string;
}

export function isPerson(person: Person | number): person is Person {
  return (person as Person).id !== undefined;
}

export function isPersonJson(
  person: PersonJson | number
): person is PersonJson {
  return (person as PersonJson).id !== undefined;
}
