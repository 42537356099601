import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

type DatePickerProps = {
  name: string;
  defaultDate?: Date;
  onChange: (e: any) => void;
  fullWidth: boolean;
  ariaLabel: string;
  label: string;
  disablePast?: boolean;
  error?: boolean;
  helperText?: string;
  variant: "standard" | "filled" | "outlined" | undefined;
  onBlur?: (e: any) => void;
};

function YarsDatePicker(props: DatePickerProps) {
  function onDateChange(date: Date) {
    props.onChange(date);
  }

  return (
    <DatePicker
      toolbarFormat="MM/DD/YYYY"
      disablePast={props.disablePast ?? false}
      label={props.label}
      renderInput={(params) => (
        <TextField
          {...params}
          id="date-picker"
          name={props.name}
          onBlur={props.onBlur}
          fullWidth={props.fullWidth}
          error={props.error ?? false}
          helperText={props.helperText}
        />
      )}
      value={props.defaultDate ?? null}
      onChange={(date) =>
        date ? onDateChange(date) : new Error("Invalid Date")
      }
    />
  );
}

export default YarsDatePicker;
