import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { ApiError } from "../api/errors";
import sessionClient from "../api/sessionClient";
import ErrorMessage from "../components/ErrorMessage";
import Page from "../components/Page";
import SessionForm from "./SessionForm";
import { Session } from "./types";

function SessionEdit() {
  const [session, setSession] = useState<Session>({} as Session);
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const match = useRouteMatch(
    "/programs/:programSlug/sessions/:sessionSlug/edit"
  );

  const { sessionSlug } = useParams<{ sessionSlug: string }>();

  useEffect(() => {
    if (sessionSlug) {
      sessionClient
        .getBySlug(sessionSlug)
        .then((sessionResponse) => setSession(sessionResponse))
        .catch((e: ApiError) => setApiError(e));
    }
  }, [sessionSlug]);

  return (
    <Page>
      {apiError ? (
        <ErrorMessage message="No Session Found" />
      ) : match ? (
        session.slug && <SessionForm session={session} />
      ) : (
        <SessionForm />
      )}
    </Page>
  );
}

export default SessionEdit;
