import { CardHeader } from "@mui/material";

type YarsCardHeaderProps = {
  title: string | undefined;
  action?: React.ReactNode;
  component?: "h1" | "h2" | "h3" | "h4";
};

function YarsCardHeader(props: YarsCardHeaderProps) {
  const component = props.component ?? "h3";
  return (
    <CardHeader
      title={props.title}
      titleTypographyProps={{ component: component }}
      action={props.action}
    />
  );
}
export default YarsCardHeader;
