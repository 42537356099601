import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import PageNotFound from "../components/PageNotFound";
import Participants from "./participants/Participants";
import SessionAll from "./SessionAll";
import SessionDetail from "./SessionDetail";
import SessionEdit from "./SessionEdit";
import Staff from "./staff/Staff";

/**
 * Defines routing for the /programs/:slug/sessions endpoint
 */
export const ProgramSessions = () => {
  const match = useRouteMatch();

  if (match === null) {
    throw new Error("Sessions route not matched");
  }

  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <SessionEdit />
      </Route>
      <Route path={`${match.path}/:sessionSlug/edit`}>
        <SessionEdit />
      </Route>
      <Route path={`${match.path}/:sessionSlug/participants`}>
        <Participants />
      </Route>
      <Route path={`${match.path}/:sessionSlug/staff`}>
        <Staff />
      </Route>
      <Route path={`${match.path}/:sessionSlug`}>
        <SessionDetail />
      </Route>
      <Route path={`${match.path}`}>
        <Redirect to={getProgramRedirectUrl(match.url)} />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export const Sessions = () => {
  const match = useRouteMatch();

  if (match === null) {
    throw new Error("Sessions route not matched");
  }

  return (
    <Route path={`${match.path}`}>
      <SessionAll />
    </Route>
  );
};

export function getProgramRedirectUrl(url: string) {
  return url.substring(0, url.indexOf("/sessions"));
}

export default Sessions;
