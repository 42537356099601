/**
 * Provides access to the Program YARS API
 */
import { Group, GroupJson } from "../auth/types";
import { isPerson, isPersonJson } from "../people/types";
import { Program, ProgramJson, Status } from "../programs/types";
import { Session, SessionJson } from "../sessions/types";
import { isSponsorUnit, isSponsorUnitJson } from "../sponsor-units/types";
import { api } from "./api";
import { ApiError } from "./errors";
import groupClient from "./groupClient";
import personClient from "./personClient";
import sessionClient from "./sessionClient";
import sponsorUnitClient from "./sponsorUnitClient";

/**
 * Fetches all active Programs
 */
async function getActive(): Promise<Array<Program>> {
  const response = await api.call("/programs/?active=true&ordering=name");
  const json = (await response.json()) as Array<ProgramJson>;
  return json.map((programJson) => unmarshall(programJson));
}

/**
 * Fetches program by id
 */
async function getById(id: number): Promise<Program> {
  const response = await api.call(`/programs/${id}/`);
  return unmarshall((await response.json()) as ProgramJson);
}

async function getBySlug(slug: string): Promise<Program> {
  const response = await api.call("/programs/?slug=" + slug);
  return unmarshall(((await response.json()) as ProgramJson[])[0]);
}

const getByStatus = async (status: Status) => {
  const response = await api.call(`/programs/?active=true&status=${status}`);
  const json = (await response.json()) as Array<ProgramJson>;
  return json.map((programJson) => unmarshall(programJson));
};

/**
 * Updates a program
 */
async function update(program: Partial<Program>): Promise<Program> {
  if (!program.id) {
    throw new ApiError("program.id is required");
  }

  // Enforce sponsor unit to be integer
  if (program.sponsorUnit && isSponsorUnit(program.sponsorUnit)) {
    program.sponsorUnit = program.sponsorUnit.id;
  }

  const fetchInit = {
    method: "PUT",
    body: JSON.stringify(marshall(program)),
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await api.call(`/programs/${program.id}/`, fetchInit);
  const json = (await response.json()) as ProgramJson;
  return unmarshall(json);
}

/**
 * Create a program
 */
async function create(program: Partial<Program>): Promise<Program> {
  if (program.sponsorUnit && isSponsorUnit(program.sponsorUnit)) {
    program.sponsorUnit = program.sponsorUnit.id;
  }
  const fetchBody = {
    method: "POST",
    body: JSON.stringify(marshall(program)),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await api.call("/programs/", fetchBody);
  const json = (await response.json()) as ProgramJson;
  return unmarshall(json);
}

/**
 * Deletes a program
 */
async function deleteById(id: number): Promise<void> {
  const fetchBody = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };
  await api.call(`/programs/${id}/`, fetchBody);
}

/**
 * Get programs sessions by program id
 */
async function getSessionsForProgram(
  program_id: number
): Promise<Array<Session>> {
  const response = await api.call(`/programs/${program_id}/sessions/`);
  const json = (await response.json()) as Array<SessionJson>;
  return json.map((sessionJson) => sessionClient.unmarshall(sessionJson));
}

async function addUserPermission(
  program: Program,
  userId: number,
  groupId: number
): Promise<void> {
  const data = { user_id: userId, group_id: groupId };
  const fetchBody = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await api.call(
    `/programs/${program.id}/permissions/`,
    fetchBody
  );
  await response.json();
}

async function removeUserPermission(
  program: Program,
  userId: number,
  groupId: number
) {
  const data = { user_id: userId, group_id: groupId };
  const fetchBody = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await api.call(`/programs/${program.id}/permissions/`, fetchBody);
}

/**
 * Marshall a Program object into a JSON representation for creation and updates
 */
function marshall(program: Partial<Program>): Partial<ProgramJson> {
  const sponsorUnit = program.sponsorUnit;
  return {
    id: program.id,
    name: program.name,
    active: program.active,
    status: program.status,
    slug: program.slug,
    sponsor_unit:
      sponsorUnit && isSponsorUnit(sponsorUnit) ? sponsorUnit.id : sponsorUnit,
    director:
      program.director && isPerson(program.director)
        ? program.director.id
        : program.director,
    website: program.website,
    created_at: program.createdAt ? program.createdAt.toISOString() : undefined,
    updated_at: program.updatedAt ? program.updatedAt.toISOString() : undefined,
    liaison_comments: program.liaisonComments,
  };
}

/**
 * Unmarshall a Program from its JSON representation
 */
function unmarshall(json: ProgramJson): Program {
  return {
    id: json.id,
    name: json.name,
    active: json.active,
    status: json.status,
    slug: json.slug,
    sponsorUnit:
      json.sponsor_unit && isSponsorUnitJson(json.sponsor_unit)
        ? sponsorUnitClient.unmarshall(json.sponsor_unit)
        : json.sponsor_unit,
    director:
      json.director && isPersonJson(json.director)
        ? personClient.unmarshall(json.director)
        : json.director,
    website: json.website,
    sessions: parseSessions(json.session_set),
    groups: parseGroups(json.groups),
    permissions: json.permissions,
    createdAt: json.created_at ? new Date(json.created_at) : undefined,
    updatedAt: json.updated_at ? new Date(json.updated_at) : undefined,
    liaisonComments: json.liaison_comments,
  };
}

function parseGroups(
  json: Array<GroupJson> | undefined
): Array<Group> | undefined {
  if (json) {
    return json.map((val) => groupClient.unmarshall(val));
  }
  return undefined;
}

function parseSessions(
  json: Array<SessionJson> | undefined
): Array<Session> | undefined {
  if (json) {
    return json.map((val) => sessionClient.unmarshall(val));
  }
  return undefined;
}

const programClient = {
  getActive: getActive,
  getById: getById,
  getBySlug: getBySlug,
  getByStatus: getByStatus,
  update: update,
  create: create,
  deleteById: deleteById,
  getSessionsForProgram: getSessionsForProgram,
  addUserPermission: addUserPermission,
  removeUserPermission: removeUserPermission,
  marshall: marshall,
  unmarshall: unmarshall,
};

export default programClient;
