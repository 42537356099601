import { CircularProgress, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import userClient from "../../api/userClient";
import { PublicUser } from "../../auth/types";

interface UserPickerProps {
  label: string;
  onUserChange: (user: PublicUser) => void;
  defaultValue?: PublicUser;
  customStyle?: React.CSSProperties;
}

function UserPicker(props: UserPickerProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<PublicUser[]>([]);
  const [loading, setLoading] = React.useState(false);

  /**
   * If not currently loading get, and set the people.
   */
  React.useEffect(() => {
    if (loading) {
      userClient
        .getAll()
        .then((users: PublicUser[]) => setOptions(users))
        .catch((e: Error) => {
          console.error(`Unable to fetch users: ${e.message}`);
        })
        .finally(() => setLoading(false));
    }
  }, [loading]);

  /**
   * When the select is closed set the options to null.
   */
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  /**
   * On input change update state
   */
  function handleChange(e: any, value: PublicUser | null) {
    if (value) {
      props.onUserChange(value);
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Autocomplete
          open={open}
          defaultValue={props.defaultValue}
          id="user-picker"
          onOpen={() => {
            setOpen(true);
            setLoading(true);
          }}
          onClose={() => {
            setOpen(false);
            setLoading(false);
          }}
          onChange={(event, value) => handleChange(event, value)}
          getOptionLabel={(option) => option.displayName}
          options={options}
          loading={loading}
          style={props.customStyle}
          renderInput={(params) => (
            <TextField
              autoFocus
              {...params}
              label={props.label}
              fullWidth
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default UserPicker;
