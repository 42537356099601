import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import personClient from "../api/personClient";
import ErrorMessage from "../components/ErrorMessage";
import Page from "../components/Page";
import PersonForm from "./PersonForm";
import { Person } from "./types";

function PersonEdit() {
  const [person, setPerson] = useState<Person>({} as Person);
  const [error, setError] = useState<boolean>(false);
  const { personSlug } = useParams<{ personSlug: string }>();

  useEffect(() => {
    setError(false);
    if (personSlug) {
      personClient
        .getBySlug(personSlug)
        .then((personResponse) => {
          setPerson(personResponse);
        })
        .catch((e: Error) => {
          setError(true);
          console.error(
            `Could not fetch person with slug ${personSlug}: ${e.message}`
          );
        });
    }
  }, [personSlug]);

  return (
    <Page>
      {error ? (
        <ErrorMessage message="There was a problem fetching this person." />
      ) : personSlug ? (
        person.id && <PersonForm person={person} />
      ) : (
        <PersonForm />
      )}
    </Page>
  );
}

export default PersonEdit;
