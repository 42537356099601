/** Error raised when an unexpected API error occurs */
export class ApiError extends Error {
  __proto__ = Error;
  fieldErrors?: FieldErrors;
  response?: Response;
  constructor(message: string, response?: Response, fieldErrors?: FieldErrors) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = "ApiError";
    this.response = response;
    this.fieldErrors = fieldErrors || {};
  }
}

/** Error raised when a resource cannot be found (eg. 404) */
export class ResourceNotFoundError extends Error {
  __proto__ = Error;
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = "ResourceNotFoundError";
  }
}

export interface FieldErrors {
  [key: string]: string;
}
