import { Grid, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { ApiError } from "../api/errors";
import { Redirect as RedirectType } from "../common/types";
import Page from "../components/Page";
import { useSessionContext } from "../context/SessionContext";
import { WithTitle } from "../context/TitleContext";

/**
 * Initiates a log in request with the backend
 */
export const Login = () => {
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });
  const session = useSessionContext();
  const apiBaseUrl: string = process.env.REACT_APP_BACKEND_URL || "";
  const redirectUriSignin: string =
    process.env.REACT_APP_REDIRECT_URI_SIGNIN || "";
  let loginRedirectUrl: string;
  const clearUser = new URLSearchParams(useLocation().search).get("clear_user");

  if (apiBaseUrl && redirectUriSignin) {
    loginRedirectUrl = `${apiBaseUrl}/login/?redirect=${redirectUriSignin}`;
  } else {
    throw new ApiError(
      `Redirect URL could not be constructed. Backend URL: ${apiBaseUrl}, Redirect URL: ${redirectUriSignin}`
    );
  }

  if (clearUser) {
    session.clearUser();
  }

  useEffect(() => {
    if (session.user) {
      setRedirectObj({
        redirect: true,
        uri: getRedirectPath(),
      });
    } else if (!session.user) {
      window.open(loginRedirectUrl, "_self");
    }
  }, [session]);

  const getRedirectPath = () => {
    if (!session?.user?.firstName || !session?.user?.email) {
      return "/account?login_redirect=false";
    }

    return Cookies.get("login_redirect_location") || "/";
  };

  /**
   * If the redirect object's redirect key is true redirect to the URI.
   */
  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  return (
    <WithTitle title="Signing you in">
      <Page>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Stuck? <Link to="/">Click here to return home.</Link>
            </Typography>
          </Grid>
        </Grid>
      </Page>
    </WithTitle>
  );
};
