import SponsorUnitForm from "../sponsor-units/SponsorUnitForm";
import { SponsorUnit } from "../sponsor-units/types";
import {
  CloseDialogButton,
  YarsDialog,
  YarsDialogContent,
  YarsDialogTitle,
} from "./YarsDialog";

type AddSponsorUnitDialogProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleSubmit: (sponsorUnit: SponsorUnit) => void;
};

/**
 * Dialog to create a sponsor unit used if a sponsor unit does not exist within the sponsor unit dialog.
 */
function AddSponsorUnitDialog(props: AddSponsorUnitDialogProps) {
  const { open, title, handleClose, handleSubmit } = props;
  return (
    <YarsDialog
      open={open}
      aria-labelledby="add-sponsor-unit-dialog-title"
      aria-describedby="add-sponsor-unit-dialog-content"
    >
      <YarsDialogTitle id="add-sponsor-unit-dialog-title">
        {title}
        <CloseDialogButton onClose={handleClose} />
      </YarsDialogTitle>
      <YarsDialogContent
        className="uw-dialog-content"
        id="add-sponsor-unit-dialog-content"
      >
        <SponsorUnitForm
          isDialog={true}
          saveText="Add Sponsor Unit"
          onSubmit={handleSubmit}
          aria-label="Add new sponsor unit"
          handleDialogClose={props.handleClose}
        />
      </YarsDialogContent>
    </YarsDialog>
  );
}

export default AddSponsorUnitDialog;
