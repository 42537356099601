import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { PublicUser } from "../../auth/types";
import UserPicker from "./UserPicker";

type UserPickerDialogProps = {
  open: boolean;
  title: string;
  handleSubmit: (user: PublicUser) => void;
  handleClose: () => void;
};

function UserPickerDialog(props: UserPickerDialogProps) {
  const [user, setUser] = useState<PublicUser>({} as PublicUser);

  function handleSubmit() {
    props.handleSubmit(user);
  }

  return (
    <Dialog
      open={props.open}
      aria-labelledby="user-picker-dialog-title"
      aria-describedby="user-picker-dialog-content"
      fullWidth
      maxWidth="sm"
      className="uw-dialog"
    >
      <DialogTitle className="uw-dialog-title" id="user-picker-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogContent
        className="uw-dialog-content"
        id="user-picker-dialog-content"
      >
        <UserPicker label={props.title} onUserChange={setUser} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={props.handleClose}
          className="secondary-action-btn"
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={handleSubmit}
          className="primary-action-btn"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserPickerDialog;
