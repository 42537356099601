import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useTitleContext } from "../context/TitleContext";
import Page from "./Page";

type ListProps = {
  table: React.ReactElement;
  createLink?: string;
  createText?: string;
};

/**
 * A Page that renders a list of resources and provides a Create action
 */
const ListPage = function (props: ListProps) {
  const title = useTitleContext();
  return (
    <Page>
      <Grid container direction="row">
        <Grid container direction="row">
          <Grid item xs={12}>
            {props && props.createLink && (
              <Button
                aria-label={`Create new ${title.title}`}
                component={Link}
                to={props.createLink}
                className="createButton primary-action-btn"
                variant="contained"
              >
                {props.createText ? props.createText : "Create"}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid className="uw-table-container" item xs={12}>
          {props.table}
        </Grid>
      </Grid>
    </Page>
  );
};

export default ListPage;
