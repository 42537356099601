import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import NavLinkForward from "./NavLinkForward";

interface ListItemLinkProps {
  icon: React.ReactElement;
  primary: string;
  to: string;
  exact: boolean;
}

/**
 * Wraps the React Router NavLink component in a Material-UI Link component.
 * Discovered: https://material-ui.com/guides/composition/#routing-libraries
 * Note: This will need to be changed if react-router-dom is updated to ^6.0
 */
export function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, exact } = props;

  return (
    <ListItem button component={NavLinkForward} to={to} exact={exact}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
}

export default ListItemLink;
