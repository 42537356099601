import ListPage from "../components/ListPage";
import { useSessionContext } from "../context/SessionContext";
import PeopleTable from "./PeopleTable";

/**
 * Displays a list of People
 */
function PeopleList() {
  const session = useSessionContext();
  let createLink = undefined;

  if (session.user && session.user.permissions.includes("people.add_person")) {
    createLink = "/people/create";
  }

  return (
    <ListPage
      table={<PeopleTable />}
      createLink={createLink}
      createText="Create Person"
    />
  );
}

export default PeopleList;
