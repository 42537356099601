/**
 * Provides access to the Program YARS API
 */
import { Person, PersonJson } from "../people/types";
import api from "./api";
import { ApiError } from "./errors";

/**
 * Fetches All people
 */
async function getAll(): Promise<Array<Person>> {
  const response = await api.call("/people?ordering=given_name,surname");
  const json = (await response.json()) as Array<PersonJson>;
  return json.map<Person>((value) => {
    return unmarshall(value);
  });
}

/**
 * Fetch Person by ID
 */
async function getById(id: number): Promise<Person> {
  const response = await api.call(`/people/${id}/`);
  return unmarshall((await response.json()) as PersonJson);
}

/**
 * Fetch Person by Slug
 */
async function getBySlug(slug: string): Promise<Person> {
  const response = await api.call("/people/?slug=" + slug);
  return unmarshall(((await response.json()) as PersonJson[])[0]);
}

/**
 * Update a person by ID.
 */
async function update(person: Partial<Person>): Promise<Person> {
  if (!person.id) {
    throw new ApiError(`Person has missing ID: ${person.toString()}`);
  }
  const response = await api.call(`/people/${person.id}/`, {
    method: "PUT",
    body: JSON.stringify(marshall(person)),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return unmarshall((await response.json()) as PersonJson);
}

/**
 * Delete the person by ID.
 */
async function deleteById(id: number): Promise<void> {
  await api.call(`/people/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/**
 * Create a new Person
 */
async function create(person: Person): Promise<Person> {
  const response = await api.call("/people/", {
    method: "POST",
    body: JSON.stringify(marshall(person)),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return unmarshall((await response.json()) as PersonJson);
}

/** Marshalls a Person into a JSON representation for saving */
function marshall(person: Partial<Person>): Partial<PersonJson> {
  return {
    id: person.id,
    full_name: person.fullName,
    given_name: person.givenName,
    surname: person.surname,
    slug: person.slug,
    email: person.email,
    phone_number: person.phoneNumber,
    created_at: person.createdAt ? person.createdAt.toISOString() : undefined,
    updated_at: person.updatedAt ? person.updatedAt.toISOString() : undefined,
  };
}

/** Unmarshalls a JSON respresentation of a person into a Person object */
function unmarshall(json: PersonJson): Person {
  return {
    id: json.id,
    givenName: json.given_name,
    surname: json.surname,
    email: json.email,
    fullName: json.full_name,
    slug: json.slug,
    phoneNumber: json.phone_number,
    createdAt: json.created_at ? new Date(json.created_at) : undefined,
    updatedAt: json.updated_at ? new Date(json.updated_at) : undefined,
  };
}

const personClient = {
  getAll: getAll,
  getById: getById,
  getBySlug: getBySlug,
  update: update,
  create: create,
  deleteById: deleteById,
  marshall: marshall,
  unmarshall: unmarshall,
};

export default personClient;
