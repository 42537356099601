import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Redirect } from "react-router";
import { object, string } from "yup";
import authClient from "../api/authClient";
import { User } from "../auth/types";
import { Redirect as RedirectType } from "../common/types";
import FormGenerator, {
  Field,
  FormSchema,
  Group,
  WrapperProps,
} from "../components/forms/FormGenerator";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";

type ProfileFormProps = {
  user?: User;
};

export default function ProfileForm(props: ProfileFormProps) {
  const user: User = props.user ?? ({} as User);
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });

  const { setNotification } = useNotificationContext();

  function handleSubmit(
    user: User,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    if (user.id) {
      authClient
        .updateUser(user)
        .then(() => {
          setRedirectObj({
            redirect: true,
            uri: `/login?clear_user=true/`,
          });
        })
        .catch((e: Error) => {
          console.error("Unable to update program: " + e.message);
          setNotification({
            message: "A problem occurred with your request",
            type: "error",
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      setNotification({ message: "Unable to update User", type: "error" });
      setSubmitting(false);
    }
  }

  /**
   * If the redirect object's redirect key is true redirect to the URI.
   */
  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  const validationSchema = object().shape({
    firstName: string().trim().required("First Name is Required"),
    lastName: string().trim().required("Last Name is Required"),
    email: string()
      .email("Email Must Be Valid")
      .trim()
      .required("Email is Required"),
  });

  const Wrapper = (props: WrapperProps) => (
    <Grid item xs={12} md={6}>
      {props.children}
    </Grid>
  );

  const EmailWrapper = (props: WrapperProps) => (
    <Grid item xs={12}>
      {props.children}
    </Grid>
  );

  const firstNameField: Field[] = [
    {
      name: "firstName",
      prettyName: "First Name",
      type: "text",
      required: true,
      ariaLabel: "first name",
    },
  ];

  const lastNameField: Field[] = [
    {
      name: "lastName",
      prettyName: "Last Name",
      type: "text",
      required: true,
      ariaLabel: "last name",
    },
  ];

  const emailField: Field[] = [
    {
      name: "email",
      prettyName: "Email",
      type: "text",
      required: true,
      ariaLabel: "email",
    },
  ];

  const groups: Group[] = [
    {
      name: "profile-group-first",
      prettyName: "first group",
      fields: firstNameField,
      wrapper: Wrapper,
    },
    {
      name: "profile-group-second",
      prettyName: "second group",
      fields: lastNameField,
      wrapper: Wrapper,
    },
    {
      name: "profile-group-email",
      prettyName: "Email",
      fields: emailField,
      wrapper: EmailWrapper,
    },
  ];

  const formSchema: FormSchema<User> = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: user,
    groups: groups,
  };

  return (
    <Card variant="outlined" aria-label="profile form" className="uw-card">
      <YarsCardHeader component="h2" title="Preferred Contact Information" />
      <CardContent>
        <Typography style={{ paddingBottom: "1.5rem" }}>
          All users are asked to provide their preferred first name, last name
          and email. You may update this information at anytime.
        </Typography>
        <FormGenerator schema={formSchema} />
      </CardContent>
    </Card>
  );
}
