import { Paper } from "@mui/material";
import { PropsWithChildren } from "react";

/**
 * A Page is simply a Paper component with some useful styling applied. It should serve as the main background for most
 * pages in the application
 */
export default function Page(props: PropsWithChildren<{}>) {
  return <Paper className="uw-pagePaper">{props.children}</Paper>;
}
