import { Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { ApiError } from "../api/errors";
import programClient from "../api/programClient";
import ErrorMessage from "../components/ErrorMessage";
import Page from "../components/Page";
import ProgramForm from "./ProgramForm";
import { Program } from "./types";

function ProgramEdit() {
  const [program, setProgram] = useState<Program>();
  const [apiError, setApiError] = useState<ApiError | null>(null);
  const match = useRouteMatch("/programs/:programSlug/edit");
  const { programSlug } = useParams<{ programSlug: string }>();

  useEffect(() => {
    if (programSlug) {
      programClient
        .getBySlug(programSlug)
        .then((programResponse) => setProgram(programResponse))
        .catch((e: ApiError) => setApiError(e));
    }
  }, [programSlug]);

  let content: ReactElement = <ProgramForm />;

  if (apiError) {
    content = <ErrorMessage message="This program could not be found" />;
  } else if (match) {
    if (program) {
      content = <ProgramForm program={program} />;
    } else {
      content = <Typography align="center">Fetching program...</Typography>;
    }
  }

  return <Page>{content}</Page>;
}

export default ProgramEdit;
