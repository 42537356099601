import { Grid, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTitleContext } from "../context/TitleContext";

/**
 * Renders the page's primary and secondary headings
 *
 * To update the page's headings, use <WithTitle>. The primary heading is set
 * in higher-level components, so most pages will only need to set a subtitle.
 *
 * <WithTitle subtitle="My Page">
 *   <Page />
 * </WithTitle>
 */
const PageHeadings = (): ReactElement => {
  const { title, subtitle } = useTitleContext();

  if (!title && !subtitle) {
    return <></>;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      spacing={1}
      className="uw-pageHeadings"
    >
      {title && (
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            {title}
          </Typography>
        </Grid>
      )}
      {subtitle && (
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            {subtitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PageHeadings;
