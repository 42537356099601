export interface User {
  id: number;
  uid: string;
  isActive: boolean;
  isStaff: boolean;
  isSuperuser: boolean;
  isOrganizationLiaison: boolean;
  permissions: string[];
  organization: Organization;
  groups: number[];
  netid?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface UserJson {
  id: number;
  username: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  is_organization_liaison: boolean;
  permissions: string[];
  organization: OrganizationJson;
  groups: number[];
  netid?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

/**
 * For a public facing view of users, mostly for permissions
 */
export interface PublicUser {
  id: number;
  username: string;
  displayName: string;
  firstName?: string;
  lastName?: string;
}

export interface PublicUserJson {
  id: number;
  username: string;
  display_name: string;
  first_name?: string;
  last_name?: string;
}

export interface Group {
  id: number;
  name: string;
  users?: PublicUser[];
  attributes: GroupAttributes;
}

export interface GroupJson {
  id: number;
  name: string;
  user_set: PublicUserJson[];
  groupattributes: GroupAttributesJson;
}

export interface GroupAttributesJson {
  friendly_name: string;
}

export interface GroupAttributes {
  friendlyName: string;
}

export interface Organization {
  id: number;
  name: string;
  eppnDomain: string;
}

export interface OrganizationJson {
  id: number;
  name: string;
  eppn_domain: string;
}

/**
 * Return true if a user can switch organizations
 */
export function canSwitchOrgs(user: User | undefined): boolean {
  return (
    user !== undefined &&
    user.permissions.includes("organizations.switch_organizations")
  );
}
