import { Box, Typography } from "@mui/material";

type ErrorMessageProps = {
  message: string;
};

/**
 * An centered error message that takes up the full width of the parent container.
 */
function ErrorMessage({ message }: ErrorMessageProps) {
  return (
    <Box component="span" m={1} style={{ textAlign: "center" }}>
      <Typography variant="body1">{message}</Typography>
    </Box>
  );
}

export default ErrorMessage;
