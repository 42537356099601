import WarningIcon from "@mui/icons-material/Warning";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { WithTitle } from "../context/TitleContext";
import Page from "./Page";

const PageNotFound: FunctionComponent = () => {
  return (
    <WithTitle title="Page Not Found" subtitle="">
      <Page>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Card
              aria-labelledby="errorPageInfo"
              className="featured-icon-card"
            >
              <CardContent>
                <WarningIcon />
                <Typography paragraph id="errorPageInfo">
                  {`We're sorry, we can't seem to find the page
                  you're looking for (${document.location.toString()}). It may have been removed, or never
                  existed. If you need help, please visit our `}
                  <a href="/support">Support</a> page or return
                  <a href="/"> Home</a>.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Page>
    </WithTitle>
  );
};

export default PageNotFound;
