import Delete from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import groupClient from "../api/groupClient";
import { DEFAULT_ERROR_NOTIFICATION } from "../common/constants";
import UserPickerDialog from "../components/forms/UserPickerDialog";
import YarsTable, { yarsActions } from "../components/YarsTable";
import { useNotificationContext } from "../context/NotificationContext";
import { Group, PublicUser } from "./types";

interface ManageGroupProps {
  group: Group;
  dialogOpen: boolean;
  handleClose: () => void;
}

interface UserTableProps {
  title: string;
  users: PublicUser[];
  handleRemove: (user: PublicUser | PublicUser[]) => void;
}

function ManageGroup(props: ManageGroupProps) {
  const [members, setMembers] = useState<Array<PublicUser>>([]);
  const { setNotification } = useNotificationContext();
  const group = props.group;

  useEffect(() => {
    groupClient
      .getMembersByGroupId(group.id)
      .then((members) => setMembers(members))
      .catch(() => {
        setNotification({
          message: DEFAULT_ERROR_NOTIFICATION,
          type: "error",
        });
      });
  }, [group]);

  function handleSubmit(user: PublicUser) {
    groupClient
      .addMember(group.id, user.id)
      .then((members) => {
        setMembers(members);
        setNotification({
          message: `${user.displayName} has been added to ${group.attributes.friendlyName}`,
          type: "success",
        });
      })
      .catch(() => {
        setNotification({
          message: DEFAULT_ERROR_NOTIFICATION,
          type: "error",
        });
      })
      .finally(() => props.handleClose());
  }

  function handleRemove(user: PublicUser | PublicUser[]) {
    if (!Array.isArray(user)) {
      groupClient
        .removeMember(group.id, user.id)
        .then((members) => {
          setMembers(members);
          setNotification({
            message: `${user.displayName} was removed from ${group.attributes.friendlyName}`,
            type: "success",
          });
        })
        .catch(() => {
          setNotification({
            message: DEFAULT_ERROR_NOTIFICATION,
            type: "error",
          });
        });
    }
  }

  return (
    <>
      <UserPickerDialog
        open={props.dialogOpen}
        title="Select User By Username"
        handleSubmit={handleSubmit}
        handleClose={props.handleClose}
      />
      <section className="userPickerCard uw-card-details">
        <UserTable
          aria-label="Group management card"
          users={members}
          title="Program Admins"
          handleRemove={handleRemove}
        />
      </section>
    </>
  );
}

function UserTable(props: UserTableProps) {
  const { users } = props;
  function renderActions(user: PublicUser) {
    return (
      <IconButton
        aria-label="delete user"
        onClick={() => props.handleRemove(user)}
      >
        <Delete />
      </IconButton>
    );
  }

  const columns: GridColDef[] = [
    { field: "username", headerName: "Username", flex: 1 },
    {
      field: "actions",
      renderCell: (params: GridRenderCellParams) => renderActions(params.row),
      ...yarsActions,
    },
  ];

  return (
    <YarsTable
      ariaLabel="group management table"
      rows={users}
      columns={columns}
      showToolbar={false}
    />
  );
}

export default ManageGroup;
