import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiError } from "../api/errors";
import personClient from "../api/personClient";
import ErrorMessage from "../components/ErrorMessage";
import YarsTable, { yarsActions } from "../components/YarsTable";
import { Person } from "./types";

function PeopleTable() {
  const [people, setPeople] = useState<Array<Person>>([]);
  const [apiError, setApiError] = useState<ApiError>({} as ApiError);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setIsLoading(true);
    personClient
      .getAll()
      .then((returnedPeople) => setPeople(returnedPeople))
      .catch((e: ApiError) => setApiError(e))
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);

  function renderActions(person: Person) {
    const detailsLink = `/people/${person.slug}`;
    const detailsLinkLabel = `View ${person.fullName} details`;
    return (
      <Link
        to={detailsLink}
        className="table-action-link"
        aria-label={detailsLinkLabel}
      >
        <ReadMoreIcon /> View
      </Link>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
    },
    {
      hide: isMobile ? true : false,
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "actions",
      renderCell: (params: GridRenderCellParams) => renderActions(params.row),
      ...yarsActions,
    },
  ];

  return (
    <>
      {apiError.message ? (
        <ErrorMessage message="Unable to Fetch People" />
      ) : (
        <YarsTable
          ariaLabel="people table"
          rows={people}
          columns={columns}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

export default PeopleTable;
