import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiError } from "../api/errors";
import sponsorUnitClient from "../api/sponsorUnitClient";
import ErrorMessage from "../components/ErrorMessage";
import YarsTable, { yarsActions } from "../components/YarsTable";
import { isPerson } from "../people/types";
import { SponsorUnit } from "./types";

/**
 * A table that is the sponsor units
 */
function SponsorUnitsTable() {
  const [sponsorUnits, setSponsorUnits] = useState<Array<SponsorUnit>>([]);
  const [apiError, setApiError] = useState<ApiError>({} as ApiError);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setIsLoading(true);
    sponsorUnitClient
      .getAll()
      .then((sponsorUnits) => setSponsorUnits(sponsorUnits))
      .catch((e: ApiError) => setApiError(e))
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);

  function renderActions(sponsorUnit: SponsorUnit) {
    const detailsLink = `/sponsor-units/${sponsorUnit.slug}`;
    const detailsLinkLabel = `View ${sponsorUnit.name} Sponsoring Unit details`;
    return (
      <Link
        to={detailsLink}
        className="table-action-link"
        aria-label={detailsLinkLabel}
      >
        <ReadMoreIcon /> View
      </Link>
    );
  }

  function renderResPersonName(sponsorUnit: SponsorUnit) {
    if (sponsorUnit.resPerson && isPerson(sponsorUnit.resPerson)) {
      return sponsorUnit?.resPerson.fullName;
    }
  }

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      hide: isMobile ? true : false,
      field: "resPerson",
      renderCell: (params: GridRenderCellParams) =>
        renderResPersonName(params.row),
      headerName: "Dean or Director",
      flex: 1,
    },
    {
      field: "actions",
      renderCell: (params: GridRenderCellParams) => renderActions(params.row),
      ...yarsActions,
    },
  ];

  return (
    <>
      {apiError.message ? (
        <ErrorMessage message="Unable to Fetch Sponsor Units" />
      ) : (
        <YarsTable
          ariaLabel="sponsor units table"
          rows={sponsorUnits}
          columns={columns}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

export default SponsorUnitsTable;
