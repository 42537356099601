import ClassIcon from "@mui/icons-material/Class";
import DeleteIcon from "@mui/icons-material/Delete";
import EventIcon from "@mui/icons-material/Event";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { ReactElement, useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { ApiError } from "../api/errors";
import sessionClient from "../api/sessionClient";
import { Redirect as RedirectType } from "../common/types";
import ErrorMessage from "../components/ErrorMessage";
import DeleteDialog from "../components/forms/DeleteDialog";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";
import { WithTitle } from "../context/TitleContext";
import NavLinkForward from "../navigation/NavLinkForward";
import { isPerson } from "../people/types";
import { isProgram } from "../programs/types";
import ParticipantCard from "./participants/ParticipantCard";
import StaffCard from "./staff/StaffCard";
import { Session } from "./types";

function SessionDetail() {
  const [session, setSession] = useState<Session>({} as Session);
  const [apiError, setApiError] = useState<ApiError | null>(null);
  const { sessionSlug } = useParams<{ sessionSlug: string }>();

  useEffect(() => {
    if (sessionSlug) {
      sessionClient
        .getBySlug(sessionSlug)
        .then((sessionResponse: Session) => setSession(sessionResponse))
        .catch((e: ApiError) => setApiError(e));
    } else {
      console.error("No session slug");
    }
  }, [sessionSlug]);

  return (
    <>
      {apiError ? (
        <ErrorMessage message="No Session Found" />
      ) : (
        <WithTitle title={session.name}>
          <Card
            sx={{ mb: 2 }}
            role="group"
            variant="outlined"
            aria-label="Session summary"
            className="uw-card-details"
          >
            <YarsCardHeader title="Summary" />
            <CardContent>
              {session.program && isProgram(session.program) && (
                <Typography variant="body1" sx={{ mb: 1.5 }}>
                  Session under&nbsp;
                  <a
                    href={`/programs/${session.program.slug}`}
                    className="button-style-link"
                  >
                    {session.program.name}
                  </a>{" "}
                  program.
                </Typography>
              )}
              <Typography variant="body1">{session.description}</Typography>
            </CardContent>
          </Card>
          <SessionDetailContent session={session} />
        </WithTitle>
      )}
    </>
  );
}

const SessionDetailContent = (props: { session: Session }) => {
  const { session } = props;
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      className="uw-detailsPage"
    >
      <Grid item xs={12} md={12} lg={4}>
        <SessionStatusCard session={session} />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <ParticipantCard session={session} />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <StaffCard session={session} />
      </Grid>
    </Grid>
  );
};

function SessionStatusCard(props: { session: Session }) {
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });
  const { setNotification } = useNotificationContext();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const session = props.session;
  const location = useLocation();
  const { programSlug } = useParams<{ programSlug: string }>();

  const startDate = session.startDate ? (
    DateTime.fromJSDate(session.startDate).toLocaleString(DateTime.DATE_FULL)
  ) : (
    <>No Start Date Given</>
  );

  const endDate = session.endDate ? (
    DateTime.fromJSDate(session.endDate).toLocaleString(DateTime.DATE_FULL)
  ) : (
    <>No End Date Given</>
  );

  function DirectorListItem(props: {
    director: Session["director"];
  }): ReactElement {
    let to = "";
    let disabled = true;
    let text = "Not Assigned";

    if (props.director && isPerson(props.director)) {
      to = `/people/${props.director.slug}`;
      disabled = false;
      text = props.director.fullName;
    }

    return (
      <ListItem component={NavLinkForward} to={to} disabled={disabled} button>
        <ListItemAvatar>
          <Avatar className="avatar-icon">
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Session Director" secondary={text} />
      </ListItem>
    );
  }

  function handleDelete() {
    setDeleting(true);
    const sessionName = session.name;
    const sessionId = session.id;
    sessionClient
      .deleteById(session.id)
      .then(() => {
        setNotification({
          message: `Deleted session ${sessionName ?? ""}.`,
          type: "success",
        });
        setRedirectObj({
          redirect: true,
          uri: "/programs/" + programSlug + "/",
        });
      })
      .catch((e: Error) => {
        console.error(
          `Unable to delete session ${sessionId ?? ""}: ${e.message}`
        );
        setNotification({
          message: `Unable to delete ${sessionName ?? ""}`,
          type: "error",
        });
        setDeleting(false);
      });
  }

  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  function openDeleteDialog() {
    setDeleteDialogOpen(true);
  }

  function closeDeleteDialog() {
    setDeleteDialogOpen(false);
  }

  const deleteDialogContent = (
    <>
      Are you sure you would like to delete <strong>{session.name}</strong>?
    </>
  );

  return (
    <Card
      role="group"
      variant="outlined"
      aria-label="Session details"
      className="uw-card-details"
    >
      <DeleteDialog
        open={deleteDialogOpen}
        title="Confirm Deletion"
        content={deleteDialogContent}
        handleDelete={handleDelete}
        handleClose={closeDeleteDialog}
        isDeleting={isDeleting}
      />
      <YarsCardHeader title="Session Details" />
      <CardContent>
        <List dense={true} className="details-list">
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ClassIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionName"
              primary="Name"
              secondary={session.name}
            />
          </ListItem>
          <DirectorListItem director={session.director} />
          <ListItem aria-labelledby="sessionStartDay">
            <ListItemAvatar>
              <Avatar>
                <EventIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionStartDay"
              primary="Start Date"
              secondary={startDate}
            />
          </ListItem>
          <ListItem aria-labelledby="sessionEndDay">
            <ListItemAvatar>
              <Avatar>
                <EventIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionEndDate"
              primary="End Date"
              secondary={endDate}
            />
          </ListItem>
          <ListItem aria-labelledby="sessionType">
            <ListItemAvatar>
              <Avatar>
                <MapsHomeWorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionType"
              primary="Type"
              secondary={formattedType(session)}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Box m={2}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                className="primary-action-btn"
                variant="outlined"
                component={NavLinkForward}
                to={`${location.pathname}/edit`}
                size="medium"
              >
                Edit Session
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="secondary-action-btn"
                onClick={openDeleteDialog}
                variant="outlined"
                size="medium"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardActions>
    </Card>
  );
}

/**
 * Return a fully formatted string representing the type of this session
   @param session Session
   @returns string
 */
const formattedType = (session: Session) => {
  if (session.typeCode === "res") {
    return `${session.typeDisplay!}: ${session.primaryOvernightLocation!}`;
  }

  return `${session.typeDisplay!}` || "Unknown";
};

export default SessionDetail;
