import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CreateIcon from "@mui/icons-material/Create";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { csvBooleanText, csvDateFormat } from "../common/functions";
import YarsTable, {
  yarsActions,
  yesNoValueOptions,
} from "../components/YarsTable";
import { isProgram } from "../programs/types";
import { Session } from "./types";

/**
 * Table to show all sessions
 */
function SessionsTable(props: { sessions: Session[] }) {
  const sessions = props.sessions;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Get the edit icon button and link for a session. If a program doesn't exist
   * for the session then return a disabled icon button.
   */
  function renderActions(session: Session) {
    if (isProgram(session.program)) {
      const detailsLink = `/programs/${session.program.slug}/sessions/${session.slug}`;
      const detailsLinkLabel = `View session ${session.name} details`;
      return (
        <Link
          to={detailsLink}
          aria-label={detailsLinkLabel}
          className="table-action-link"
        >
          <ReadMoreIcon /> View
        </Link>
      );
    }

    console.warn(`Could not create session edit URL for session ${session.id}`);

    function getDisabledEditIconButton() {
      const disabled = true;
      return (
        <Tooltip title="Edit">
          <IconButton
            size="medium"
            disabled={disabled}
            aria-disabled={disabled}
          >
            <CreateIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return getDisabledEditIconButton();
  }

  function getBooleanText(value: boolean) {
    if (value) {
      return (
        <span className="positive">
          <CheckIcon className="status-icon" /> Yes
        </span>
      );
    }

    return (
      <span className="negative">
        <ClearIcon className="status-icon" /> No
      </span>
    );
  }

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      hide: isMobile ? true : false,
      field: "startDate",
      headerName: "Start Date",
      type: "date",
      valueFormatter: ({ value }) => csvDateFormat(value),
      renderCell: (params: GridRenderCellParams) =>
        DateTime.fromJSDate(params.value).toLocaleString(),
      flex: 1,
    },
    {
      hide: isMobile ? true : false,
      field: "endDate",
      headerName: "End Date",
      type: "date",
      flex: 1,
      valueFormatter: ({ value }) => csvDateFormat(value),
      renderCell: (params: GridRenderCellParams) =>
        DateTime.fromJSDate(params.value).toLocaleString(),
    },
    {
      hide: isMobile ? true : false,
      field: "typeDisplay",
      headerName: "Type",
      flex: 1,
    },
    {
      hide: isMobile ? true : false,
      field: "isCompliant",
      headerName: "Is Compliant",
      valueFormatter: ({ value }) => csvBooleanText(value),
      renderCell: (params: GridRenderCellParams) =>
        getBooleanText(params.value),
      flex: 1,
      type: "singleSelect",
      valueOptions: yesNoValueOptions,
    },
    {
      hide: isMobile ? true : false,
      field: "staffBackgroundChecks",
      headerName: "Staff Background Checks",
      valueFormatter: ({ value }) => csvBooleanText(value),
      renderCell: (params: GridRenderCellParams) =>
        getBooleanText(params.value),
      flex: 1,
      type: "singleSelect",
      valueOptions: yesNoValueOptions,
    },
    {
      hide: isMobile ? true : false,
      field: "staffRequiredTraining",
      headerName: "Staff Required Training",
      valueFormatter: ({ value }) => csvBooleanText(value),
      renderCell: (params: GridRenderCellParams) =>
        getBooleanText(params.value),
      flex: 1,
      type: "singleSelect",
      valueOptions: yesNoValueOptions,
    },
    {
      hide: isMobile ? true : false,
      field: "staffCount",
      headerName: "Staff",
      flex: 1,
      type: "number",
    },
    {
      hide: isMobile ? true : false,
      field: "participantCount",
      headerName: "Participants",
      flex: 1,
      type: "number",
    },
    {
      field: "actions",
      renderCell: (params: GridRenderCellParams) => renderActions(params.row),
      ...yarsActions,
    },
  ];

  return (
    <YarsTable ariaLabel="sessions table" rows={sessions} columns={columns} />
  );
}

export default SessionsTable;
