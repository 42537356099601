import React, { useContext, useState } from "react";
import { Notification } from "../common/types";

type NotificationContextProps = {
  notification: Notification;
  setNotification: (notification: Notification) => void;
};

type NotificationProviderProps = {
  children?: React.ReactNode;
};

const NotificationContext = React.createContext<NotificationContextProps>({
  notification: {} as Notification,
  setNotification: () => {},
});

export function NotificationProvider(props: NotificationProviderProps) {
  const [notification, setNotification] = useState<Notification>(
    {} as Notification
  );
  const { children } = props;

  return (
    <NotificationContext.Provider
      value={{
        notification: notification,
        setNotification: setNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export const NotificationConsumer = NotificationContext.Consumer;
export const useNotificationContext = () => useContext(NotificationContext);
export default NotificationContext;
