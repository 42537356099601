import { PublicUser, PublicUserJson } from "../auth/types";
import api from "./api";

async function getAll(): Promise<PublicUser[]> {
  const response = await api.call("/users/");
  const json = (await response.json()) as Array<PublicUserJson>;
  return json.map((userJson) => unmarshall(userJson));
}
export function unmarshall(json: PublicUserJson): PublicUser {
  return {
    id: json["id"],
    username: json["username"],
    displayName: json["display_name"],
    firstName: json["first_name"],
    lastName: json["last_name"],
  };
}

const userClient = {
  unmarshall: unmarshall,
  getAll: getAll,
};

export default userClient;
